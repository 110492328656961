import React, { Component } from "react";

// Components
import SpendTable from "./SpendTable";

import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

class SpendingsList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.fetchSpendings();
  }
  render() {
    if (this.props.spendings && this.props.profile) {
      let mangafSpendings = this.props.spendings.filter(
        order => order.branch === "Mangaf"
      );
      let fintasSpendings = this.props.spendings.filter(
        order => order.branch === "Fintas 1"
      );
      let fintas2Spendings = this.props.spendings.filter(
        order => order.branch === "Fintas 2"
      );
      let sulaibiyaSpendings = this.props.spendings.filter(
        order => order.branch === "Sulaibiya"
      );
      return (
        <div className="card col-11 m-auto p-0">
          <h1 className="card-header text-center ">مصروفات المنقف </h1>
          <div className="card-body">
            {mangafSpendings.length !== 0 ? (
              <SpendTable spendings={mangafSpendings} />
            ) : (
              <p className="text-center text-danger"> No Spendings </p>
            )}
          </div>

          <h1 className="card-header text-center ">مصروفات فرع الفنطاس ١ </h1>
          <div className="card-body">
            {fintasSpendings.length !== 0 ? (
              <SpendTable spendings={fintasSpendings} />
            ) : (
              <p className="text-center text-danger"> No Spendings </p>
            )}
          </div>

          <h1 className="card-header text-center "> مصروفات فرع الفنطاس ٢ </h1>
          <div className="card-body">
            {fintas2Spendings.length !== 0 ? (
              <SpendTable spendings={fintas2Spendings} />
            ) : (
              <p className="text-center text-danger"> No Spendings </p>
            )}
          </div>

          <h1 className="card-header text-center "> مصروفات فرع صليبيه </h1>
          <div className="card-body">
            {sulaibiyaSpendings.length !== 0 ? (
              <SpendTable spendings={sulaibiyaSpendings} />
            ) : (
              <p className="text-center text-danger"> No Spendings </p>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  spendings: state.spend.spendings,
  profile: state.auth.profile
});
const mapDispatchToProps = dispatch => ({
  fetchSpendings: () => dispatch(actionCreators.fetchSpendings())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpendingsList);
