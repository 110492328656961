import React, { Component } from "react";

import { connect } from "react-redux";

// Actions
import * as actionCreators from "../../store/actions";

class NewGrocery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceNo: "",
      name: "",
      total: 0.0
    };

    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async submitHandler() {
    await this.props.createGrocery(this.state);
    this.props.history.push("/home");
  }

  render() {
    return (
      <div className="card col-lg-6 col-md-8 col-sm-11 mx-auto p-0 mt-5">
        <div className="card-body">
          <h2 className="card-title text-right">تسجيل ماجلة</h2>
          <form onSubmit={this.submitHandler} noValidate>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="رقم الفاتوره"
                name="invoiceNo"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="الاسم"
                name="name"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="number"
                placeholder="المجموع"
                name="total"
                required
                onChange={this.changeHandler}
              />
            </div>

            <h1
              className="btn btn-success float-right"
              onClick={() => this.submitHandler()}
            >
              Submit
            </h1>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  createGrocery: income => dispatch(actionCreators.createGrocery(income))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewGrocery);
