import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";

class AuthButton extends Component {
  render() {
    let buttons;
    if (!this.props.user) {
      buttons = [
        <ul key="loginlist" className="navbar-nav mr-5">
          <li key="loginButton" className="nav-item active">
            <Link to="/login" className="nav-link">
              Login
            </Link>
          </li>
        </ul>
      ];
    } else {
      buttons = [
        <ul key="logoutlist" className="navbar-nav mr-5" key="dropdown">
          <li key="logout button" className="nav-item dropdown mr-4">
            <div
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>{this.props.user.username.toUpperCase()}</span>
            </div>
            <div
              className="dropdown-menu  bg-light "
              aria-labelledby="navbarDropdown"
            >
              <span className="dropdown-item" onClick={this.props.logout}>
                <span className="p">Logout</span>
              </span>
            </div>
          </li>
          {this.props.cart ? (
            <li key="cartButton" className="nav-item active">
              <Link className="btn btn-outline-light btn-md" to="/cart">
                {" Cart "}
                <span className="badge badge-light" />
              </Link>
            </li>
          ) : (
            <></>
          )}
        </ul>
      ];
    }

    return <ul className="navbar-nav ml-auto">{buttons}</ul>;
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    cart: state.cart.cart
  };
};
const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(actionCreators.logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthButton);
