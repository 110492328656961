import React, { Component } from "react";
import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";
import StockOrdersTable from "./StockOrdersTable";

class StockOrdersBranch extends Component {
  componentDidMount() {}
  render() {
    if (this.props.loading) {
      return <h2>Loading...</h2>;
    } else {
      let orders = this.props.stockOrders.filter(
        order => order.branch === this.props.profile.branch
      );

      return (
        <div className="card col-11 m-auto p-0">
          <h1 className="card-header bg-light text-dark text-center">
            {" "}
            طلبات الستوكات{" "}
          </h1>
          <StockOrdersTable stockOrders={orders} area={"طلبات فرعي"} />
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  stockOrders: state.stockCart.orders,
  profile: state.auth.profile
});
const mapDispatchToProps = dispatch => ({
  fetchStockOrders: () => dispatch(actionCreators.fetchStockOrders())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockOrdersBranch);
