import React, { Component } from "react";
import { connect } from "react-redux";
import NumericInput from "react-numeric-input";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import * as actionCreators from "../../store/actions";

class CartRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: this.props.orderItem.quantity,
      notes: this.props.orderItem.notes,
      addOns: this.props.orderItem.addOns.map(addOn => {
        let id = addOn.id;
        return id;
      })
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleUpdate() {
    let orderItem = this.props.orderItem;
    orderItem.quantity = this.state.quantity;
    orderItem.notes = this.state.notes;
    orderItem.addOns = this.state.addOns;
    this.props.updateOrderItem(orderItem, this.props.history);
  }

  handleQuantityChange(number) {
    this.setState({ quantity: number });
  }

  render() {
    const orderItem = this.props.orderItem;
    const varient = this.props.varients.find(
      varient => varient.id === orderItem.itemVarient.id
    );
    const item = this.props.items.find(item => item.id === varient.item);
    let placeHolder =
      "https://i0.wp.com/hranew.com/wp-content/uploads/2015/04/shop-placeholder.png";

    let addOns = orderItem.addOns.map(addOn => addOn.name + ", ");

    return (
      <tr className="tableMid">
        <td>
          <img
            style={{ width: "100px", height: "100px" }}
            src={item.image || placeHolder}
            alt="item"
          />
        </td>
        <td>
          <h4 className="border-0 text-dark">{item.name}</h4>
          <p>
            {varient.varient}- {addOns}
          </p>
        </td>
        <td>
          <NumericInput
            strict={true}
            className="border-0"
            size="5"
            onChange={this.handleQuantityChange}
            min={1}
            max={item.stock}
            value={this.state.quantity}
          />
        </td>
        <td>{orderItem.total.toFixed(3)}</td>
        <td>{(orderItem.total * this.state.quantity).toFixed(3)}</td>
        <td>
          <input
            className="form-control mx-auto "
            style={{ width: "80%" }}
            type="text"
            placeholder="Notes"
            value={this.state.notes}
            name="notes"
            required
            onChange={this.handleChange}
          />
        </td>
        <td>
          <button
            onClick={() => this.props.deleteOrderItem(orderItem.id)}
            className="btn btn-outline-danger"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </td>
        <td>
          <button
            onClick={() => this.handleUpdate()}
            className="btn btn-outline-warning"
          >
            Save Changes
          </button>
        </td>
      </tr>
    );
  }
}
const mapStateToProps = state => {
  return {
    varients: state.items.varients,
    items: state.items.items
  };
};
const mapActionToState = dispatch => {
  return {
    deleteOrderItem: orderitem_id =>
      dispatch(actionCreators.deleteOrderItem(orderitem_id)),
    updateOrderItem: (orderItem, history) =>
      dispatch(actionCreators.updateOrderItem(orderItem, history))
  };
};
export default connect(
  mapStateToProps,
  mapActionToState
)(CartRow);
