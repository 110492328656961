import React, { Component } from "react";
import { Link } from "react-router-dom";

class OrderRow extends Component {
  getTextColor(status) {
    switch (status) {
      case "Cart":
        return "text-dark";
      case "Ordered":
        return "text-primary";
      case "Proccessing":
        return "text-warning";
      case "Out For Delivery":
        return "text-info";
      case "Delivered":
        return "text-success";
      case "Cancelled":
        return "text-danger";
      case "Dine In":
        return "text-secondary";
      default:
      // code block
    }
  }
  render() {
    const order = this.props.order;
    if (order.id) {
      let date =
        order.date.slice(8, 10) +
        "-" +
        order.date.slice(5, 7) +
        "-" +
        order.date.slice(0, 4);
      let time = order.date.slice(11, 19);
      return (
        <tr>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order,
                },
              }}
            >
              {order.id}
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order,
                },
              }}
            >
              {time}
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order,
                },
              }}
            >
              {date}
            </Link>
          </td>
          <td>
            <Link
              className={this.getTextColor(order.status)}
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order,
                },
              }}
            >
              {order.status}
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order,
                },
              }}
            >
              {order.branch}
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order,
                },
              }}
            >
              {order.driver}
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order,
                },
              }}
            >
              {order.discount}%
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order,
                },
              }}
            >
              {(
                parseFloat(order.total) + parseFloat(order.deliveryCharges)
              ).toFixed(3)}
              KWD
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order,
                },
              }}
            >
              {order.payment}
            </Link>
          </td>
        </tr>
      );
    } else {
      return null;
    }
  }
}

export default OrderRow;
