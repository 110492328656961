import React, { Component } from "react";

class SpendRow extends Component {
  render() {
    const spending = this.props.spending;
    if (spending.id) {
      let date =
        spending.date.slice(8, 10) +
        "-" +
        spending.date.slice(5, 7) +
        "-" +
        spending.date.slice(0, 4);
      let time = spending.date.slice(11, 19);
      return (
        <tr>
          <td>{spending.id}</td>
          <td>{spending.name}</td>
          <td>{spending.description}</td>
          <td>{spending.branch}</td>
          <td>{spending.username}</td>
          <td>{time}</td>
          <td>{date}</td>
          <td>{spending.price} KWD</td>
        </tr>
      );
    } else {
      return null;
    }
  }
}

export default SpendRow;
