import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from "react";

import DatePicker from "react-datepicker";
import StockOrdersRow from "./StockOrdersRow";
import { connect } from "react-redux";
import dateMethods from "../../store/actions/dateMethods";

class StockOrdersTable extends Component {
  state = {
    allStockOrders: true,
    driver: "",
    startDate: new Date(),
    endDate: new Date(),
  };

  filterChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeStart = (date) => {
    this.setState({
      startDate: date,
      allStockOrders: false,
    });
  };

  handleChangeEnd = (date) => {
    this.setState({
      endDate: date,
      allStockOrders: false,
    });
  };

  clearDateFilter = () => {
    this.setState({
      allStockOrders: true,
    });
  };

  render() {
    let filteredStockOrders = this.props.stockOrders;
    if (!this.state.allStockOrders) {
      filteredStockOrders = this.props.stockOrders.filter((order) => {
        let orderDate = new Date(order.date.slice(0, 10));
        if (
          dateMethods.inRange(
            orderDate,
            this.state.startDate,
            this.state.endDate
          )
        ) {
          return order;
        }
        return null;
      });
    }
    let drivers = [
      "None",
      "Qassim",
      "Faheem",
      "Jifan",
      "Shasika",
      "Erfan",
      "Haider",
      "Bandar",
      "Arsan",
      "Fawzar",
      "Saddeeq",
      "Refas",
      "Rafenda",
      "Rawan",
      "Rakshan",
      "AliHassan",
      "MohammedOmair",
    ];
    let driversList = drivers.map((driver) => {
      return (
        <option key={driver} value={driver}>
          {driver}
        </option>
      );
    });
    if (this.state.driver && this.state.driver !== "None") {
      filteredStockOrders = filteredStockOrders.filter(
        (order) => order.driver === this.state.driver
      );
    }
    const stockOrders = filteredStockOrders.map((order) => (
      <StockOrdersRow key={order.id} order={order} />
    ));
    return (
      <div className="card-body">
        <div>
          <h1 className="text-center">{this.props.area} Stock Orders</h1>
        </div>
        <div className="row mb-3">
          <div className="input-group-prepend ml-5">
            <span className="input-group-text">Filter By Date</span>
          </div>
          <DatePicker
            className="form-control"
            selected={this.state.startDate}
            onChange={this.handleChangeStart}
            selectsStart
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          />

          <div className="input-group-prepend ml-4">
            <span className="input-group-text">To</span>
          </div>
          <DatePicker
            className="form-control"
            selected={this.state.endDate}
            onChange={this.handleChangeEnd}
            selectsEnd
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          />

          <div className="ml-3" onClick={this.clearDateFilter}>
            <button className="btn btn-outline-warning">Clear Dates</button>
          </div>
        </div>

        <div className="row">
          <div className="input-group-prepend ml-5">
            <span className="input-group-text">Filter By Driver</span>
          </div>
          <select
            name="driver"
            className="form-control col-lg-4 col-md-4 col-sm-10"
            onChange={this.filterChange}
          >
            {driversList}
          </select>
        </div>

        <div className="table-responsive">
          <table className="mt-3 table">
            <thead>
              <tr>
                <th className="text-success">Order ID</th>
                <th className="text-success">Order Time</th>
                <th className="text-success">Order Date</th>
                <th className="text-success">Order Status</th>
                <th className="text-success">Order Branch</th>
                <th className="text-success">Order Driver</th>
                {this.props.profile.position !== "Cashier" ? (
                  <th className="text-success">Order Total</th>
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            <tbody>{stockOrders}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  profile: state.auth.profile,
});

export default connect(mapStateToProps)(StockOrdersTable);
