import React, { Component } from "react";
import { Link } from "react-router-dom";
// Components
import OrderItemTable from "./OrderItemTable";

import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";
import logo from "../../images/logo.png";

class OrderDetail extends Component {
  receipt() {
    let ordertemp = this.props.location.state.order;
    this.props.history.push({
      pathname: "/receipt",
      state: {
        order: ordertemp
      }
    });
  }

  render() {
    const order = this.props.location.state.order;
    if (order) {
      return (
        <div className="card  m-auto p-0">
          <div className="card-body">
            <h1 className="card-title text-center"> تفاصل الطلب </h1>
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 text-right">
                <p>سمبلي جوس</p>
                <p>هاتف : ١٨١١١٢١</p>
                <p>contact@simplyjuiceq8.com : البريد الالكتروني</p>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 text-right">
                <img className="mx-auto" src={logo} alt="logo" width="150px" />
              </div>
            </div>
            <table className="table table-bordered table-responsive-sm col-lg-6 col-md-8 float-right">
              <tbody>
                <tr>
                  <td>
                    {order.customer.name !== "None" ? (
                      <div>
                        <h5 className="text-right">
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td className="text-right size">
                                  {order.customer.name}
                                </td>
                                <td className="row-sm size">:الاسم</td>
                              </tr>
                              <tr>
                                <td className="text-right size">
                                  {order.customer.phoneNo}
                                </td>
                                <td className="row-sm size">:رقم تلفون ١</td>
                              </tr>
                              <tr>
                                <td className="text-right size">
                                  {order.customer.phoneNo2}
                                </td>
                                <td className="row-sm size">:رقم تلفون ٢</td>
                              </tr>
                              <tr>
                                <td className="text-right size">
                                  {order.customer.area}
                                </td>
                                <td className="row-sm size">:المنطقة</td>
                              </tr>
                              <tr>
                                <td className="text-right size">
                                  <address>
                                    {order.customer.block} :قطعة
                                    <br />
                                    {order.customer.street} :شارع
                                    <br />
                                    {order.customer.jaddah} :جادة
                                    <br />
                                    {order.customer.house_building} :منزل
                                    <br />
                                    {order.customer.floor} :طابق
                                    <br />
                                    {order.customer.appartment} :شقة
                                  </address>
                                </td>
                                <td className="row-sm size">:العنوان</td>
                              </tr>
                              <tr>
                                <td className="text-right size">
                                  {order.customer.extra_directions}
                                </td>
                                <td className="row-sm size">:تعليمات إضافية</td>
                              </tr>
                            </tbody>
                          </table>
                        </h5>
                      </div>
                    ) : (
                      <div />
                    )}
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">العميل</h3>
                  </th>
                </tr>
              </tbody>
            </table>
            <table className="table table-bordered table-responsive-sm col-lg-6 col-md-4">
              <tbody>
                <tr>
                  <td className="text-right">
                    <div className="dropdown mx-auto">
                      <p className="text-right">{order.id}</p>
                    </div>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">رقم الطلب</h3>
                  </th>
                </tr>
                <tr>
                  <td className="text-right">
                    <div className="dropdown mx-auto">
                      <p className="text-right">
                        {order.date.slice(0, 10)} - {order.date.slice(11, 16)}
                      </p>
                    </div>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">التاريخ و الوقت</h3>
                  </th>
                </tr>
                <tr>
                  <td className="text-right">
                    <div className="dropdown mx-auto">
                      <p className="text-right">{order.branch}</p>
                    </div>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">الفرع</h3>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div className="dropdown mx-auto">
                      <p className="text-right">{order.driver}</p>
                    </div>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">السائق</h3>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div className="dropdown mx-auto">
                      <p className="text-right">{order.payment}</p>
                    </div>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">طريقة الدفع</h3>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p className="text-right">{order.discount}%</p>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">الخصم</h3>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p className="text-right">{order.deliveryCharges}</p>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">رسوم التوصيل</h3>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div className="dropdown mx-auto">
                      <p className="text-right">{order.source}</p>
                    </div>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">مصدر الطلب</h3>
                  </th>
                </tr>
              </tbody>
            </table>

            <OrderItemTable
              order={order}
              deliveryCharges={order.deliveryCharges}
            />
            <div className="text-center">
              <Link
                to={{
                  pathname: "/editorder",
                  order: order
                }}
              >
                <button className="btn btn-success">تعديل الطلب</button>
              </Link>
              <button
                className="btn btn-success"
                onClick={() => this.receipt()}
              >
                للطباعة
              </button>
            </div>
          </div>
        </div>
      );
      // } else {
      //   return this.props.history.push("/home");
      // }
    } else {
      return (
        <div>
          <h1 className="black-title">Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  orderItems: state.cart.cartItems,
  cart: state.cart.cart,
  customers: state.cust.customers,
  loading: state.cust.loading,
  customer: state.cust.customer
});
const mapDispatchToProps = dispatch => ({
  selectCustomer: customer => dispatch(actionCreators.selectCustomer(customer)),
  updateOrder: (order, history) =>
    dispatch(actionCreators.updateOrder(order, history)),
  fetchProfile: () => dispatch(actionCreators.fetchProfile())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetail);
