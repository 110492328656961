// Types
import * as actionTypes from "./actionTypes";
import instance from "./instance";
// Add item to Cart

export const createOrder = (order, history) => {
  return dispatch => {
    instance
      .post("api/orders/create/", order)
      .then(res => res.data)
      .then(cart => {
        dispatch({
          type: actionTypes.SET_CART,
          payload: { ...cart, orderItems: [] }
        });
      })
      .then(() => history.push("/list"))
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const waiterCreateOrder = (order, history) => {
  return dispatch => {
    instance
      .post("api/orders/create/", order)
      .then(res => res.data)
      .then(cart => {
        dispatch({
          type: actionTypes.SET_CART,
          payload: { ...cart, orderItems: [] }
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const soundplay = sound => {
  return dispatch => {
    dispatch({
      type: actionTypes.SOUND,
      payload: sound
    });
  };
};

export const createOrderItem = (
  varient_id,
  order_id,
  quantity,
  notes,
  addOns
) => {
  return dispatch => {
    instance
      .post("api/order-items/create/", {
        itemVarient: varient_id,
        order: order_id,
        quantity: quantity,
        notes: notes,
        addOns: addOns
      })
      .then(res => res.data)
      .then(item => {
        dispatch({
          type: actionTypes.ADD_TO_CART,
          payload: item
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const setCart = order => dispatch => {
  dispatch({
    type: actionTypes.SET_CART,
    payload: order
  });
};

export const updateOrder = (order, history) => {
  return dispatch => {
    instance
      .put(`api/orders/${order.id}/update/`, {
        status: order.status,
        source: order.source,
        payment: order.payment,
        branch: order.branch,
        driver: order.driver,
        customer: order.customer,
        discount: order.discount,
        deliveryCharges: parseFloat(order.deliveryCharges)
      })
      .then(res => res.data)
      .then(ordere => {
        dispatch({
          type: actionTypes.UPDATE_ORDER,
          payload: ordere
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
};

// Remove item from cart
// export const removeItemFromCart = item => dispatch => {
//   dispatch({
//     type: actionTypes.REMOVE_ITEM,
//     payload: item
//   });
// };

// Checkout
export const checkoutCart = () => dispatch => {
  dispatch({
    type: actionTypes.CHECKOUT
  });
};

export const updateOrderItem = (orderitem, history) => {
  let update = {
    quantity: parseInt(orderitem.quantity),
    notes: orderitem.notes.toString(),
    addOns: orderitem.addOns
  };
  console.log(update);
  return dispatch => {
    instance
      .put(`api/order-items/${orderitem.id}/update/`, update)
      .then(res => res.data)
      .then(() => {
        dispatch({
          type: actionTypes.UPDATE_ORDERITEM,
          payload: orderitem
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const deleteOrderItem = (orderitem_id, history) => {
  return dispatch => {
    instance
      .delete(`api/order-items/${orderitem_id}/delete/`)
      .then(() => {
        dispatch({
          type: actionTypes.DELETE_ORDERITEM,
          payload: orderitem_id
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const fetchOrders = () => {
  return dispatch => {
    instance
      .get(`api/orders/`)
      .then(res => res.data)
      .then(orders => {
        dispatch({
          type: actionTypes.FETCH_ORDERS,
          payload: orders
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const fetchOrdersMonth = () => {
  return dispatch => {
    instance
      .get(`api/orders/month/`)
      .then(res => res.data)
      .then(orders => {
        dispatch({
          type: actionTypes.FETCH_ORDERS_MONTH,
          payload: orders
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const fetchOrdersDay = () => {
  return dispatch => {
    instance
      .get(`api/orders/day/`)
      .then(res => res.data)
      .then(orders => {
        dispatch({
          type: actionTypes.FETCH_ORDERS_DAY,
          payload: orders
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};
