import React, { Component } from "react";
import { Link } from "react-router-dom";

import Collapsible from "react-collapsible";
// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

// Components
import ItemCard from "./ItemCard";

import { connect } from "react-redux";

import * as actionCreators from "../store/actions";
import SearchBar from "./SearchBar.js";
class ItemList extends Component {
  state = {
    itemList: this.props.items.filter(item => item.available === true),
    filterList: this.props.items.filter(item => item.available === true)
  };

  filter = async query => {
    let newList = this.state.itemList.filter(item =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    this.setState({ filterList: newList });
  };

  componentDidUpdate(prevProps) {}
  render() {
    if (this.props.profile && this.props.items.length !== 0) {
      if (!this.props.cart) {
        if (this.props.profile.position !== ("Cashier" || "Waiter")) {
          this.props.history.push("/neworder");
        }
      }

      let twoinone = [];
      let icecream = [];
      let juicesbags = [];
      let crepe = [];
      let dishes = [];
      let energy = [];
      let fresh = [];
      let frozenjuices = [];
      let frozenyo = [];
      let hallaw = [];
      let hot = [];
      let juicebagsmixes = [];
      let lotus = [];
      let milkshakes = [];
      let mixture = [];
      let molten = [];
      let parties = [];
      let softdrinksbags = [];
      let milkshakesbags = [];
      let pancakes = [];
      let boxessection = [];
      let icedcoffee = [];

      this.state.filterList.forEach(item => {
        if (item.category === "2 in 1") {
          twoinone.push(item);
        } else if (item.category === "Ice Cream") {
          icecream.push(item);
        } else if (item.category === "Juices Bags") {
          juicesbags.push(item);
        } else if (item.category === "Crepe") {
          crepe.push(item);
        } else if (item.category === "Dishes") {
          dishes.push(item);
        } else if (item.category === "Energy Drinks") {
          energy.push(item);
        } else if (item.category === "Fresh Juices") {
          fresh.push(item);
        } else if (item.category === "Frozen Juices") {
          frozenjuices.push(item);
        } else if (item.category === "Frozen Yogurt") {
          frozenyo.push(item);
        } else if (item.category === "Hallaw") {
          hallaw.push(item);
        } else if (item.category === "Hot Drinks") {
          hot.push(item);
        } else if (item.category === "Juice Bags Mixes") {
          juicebagsmixes.push(item);
        } else if (item.category === "Lotus") {
          lotus.push(item);
        } else if (item.category === "Milkshakes") {
          milkshakes.push(item);
        } else if (item.category === "Mixture") {
          mixture.push(item);
        } else if (item.category === "Molten") {
          molten.push(item);
        } else if (item.category === "Parties") {
          parties.push(item);
        } else if (item.category === "Soft Drinks Bags") {
          softdrinksbags.push(item);
        } else if (item.category === "Milkshake Bags") {
          milkshakesbags.push(item);
        } else if (item.category === "Pan Cake") {
          pancakes.push(item);
        } else if (item.category === "Boxes Section") {
          boxessection.push(item);
        } else if (item.category === "Iced Coffee") {
          icedcoffee.push(item);
        }
      });
      const twoinoneCards = twoinone.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const iceCreamCards = icecream.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const juicesbagsCards = juicesbags.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const crepeCards = crepe.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const dishesCards = dishes.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const energyCards = energy.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const freshCards = fresh.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const frozenjuicesCards = frozenjuices.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const frozenyoCards = frozenyo.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const hallawCards = hallaw.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const hotCards = hot.map(item => <ItemCard key={item.id} item={item} />);
      const juicebagsmixesCards = juicebagsmixes.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const lotusCards = lotus.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const milkshakesCards = milkshakes.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const mixtureCards = mixture.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const moltenCards = molten.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const partiesCards = parties.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const softdrinksbagsCards = softdrinksbags.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const milkshakesbagsCards = milkshakesbags.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const pancakesCards = pancakes.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const boxessectionCards = boxessection.map(item => (
        <ItemCard key={item.id} item={item} />
      ));
      const icedcoffeeCards = icedcoffee.map(item => (
        <ItemCard key={item.id} item={item} />
      ));

      let id_icecream = "icecream";
      let id_juicebags = "juicebags";
      let id_crepe = "crepe";
      let id_dishes = "dishes";
      let id_energy = "energy";
      let id_fresh = "fresh";
      let id_frozenjuices = "frozenjuices";
      let id_halaw = "halaw";
      let id_hot = "hot";
      let id_juicebagsmixes = "juicebagsmixes";
      let id_lotus = "lotus";
      let id_milkshake = "milkshake";
      let id_mixture = "mixture";
      let id_molten = "molten";
      let id_parties = "parties";
      let id_softdrinks = "parties";
      let id_milkshakesbags = "milkshakesbags";
      let id_pancakes = "pancakes";
      let id_boxessection = "boxessection";
      let id_incedcoffee = "icedcoffee";
      let id_frozenyo = "frozenyo";

      return (
        <div className="row">
          <SearchBar filter={this.filter} />
          <div className="card col-lg-8 col-md-7 col-sm-11 p-0 mx-auto">
            <div id={id_icecream}>
              <Collapsible
                trigger="٢ في ١"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <div className="table-responsive">
                  <table className="table table-hover table-rtl">
                    <tbody>{twoinoneCards}</tbody>
                  </table>
                </div>
              </Collapsible>
            </div>
            <div id={id_juicebags}>
              <Collapsible
                trigger="ايس كريم"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{iceCreamCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_crepe}>
              <Collapsible
                trigger="اكياس العصائر"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{juicesbagsCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_dishes}>
              <Collapsible
                trigger="كريب"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{crepeCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_energy}>
              <Collapsible
                trigger="الاطباق"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{dishesCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_fresh}>
              <Collapsible
                trigger="مشروبات الطاقة"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{energyCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_frozenjuices}>
              <Collapsible
                trigger="عصائر طازجه"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{freshCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_frozenyo}>
              <Collapsible
                trigger="عصائر مثلجة"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{frozenjuicesCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_halaw}>
              <Collapsible
                trigger="الروب المثلج"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{frozenyoCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_hot}>
              <Collapsible
                trigger="حلاو"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{hallawCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_juicebagsmixes}>
              <Collapsible
                trigger="المشروبات الحارة"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{hotCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_lotus}>
              <Collapsible
                trigger="اكياس عصير الخلطات"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{juicebagsmixesCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_milkshake}>
              <Collapsible
                trigger="لوتس"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{lotusCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_mixture}>
              <Collapsible
                trigger="ملك شيك"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{milkshakesCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_molten}>
              <Collapsible
                trigger="الخلطات"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{mixtureCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_parties}>
              <Collapsible
                trigger="المولتن"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{moltenCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_softdrinks}>
              <Collapsible
                trigger="الحفلات"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{partiesCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_milkshakesbags}>
              <Collapsible
                trigger="اكياس المشروبات الغازية"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{softdrinksbagsCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_pancakes}>
              <Collapsible
                trigger="اكياس الميلك شيك"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{milkshakesbagsCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_boxessection}>
              <Collapsible
                trigger="بان كيك"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{pancakesCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <div id={id_incedcoffee}>
              <Collapsible
                trigger="قسم البوكسات"
                triggerStyle={{
                  fontSize: "40px",
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "30px",
                  verticalAlign: "middle"
                }}
                triggerClassName="mb-3 collapse-title"
                triggerOpenedClassName="opened-title"
                triggerTagName="div"
              >
                <table className="table table-hover table-rtl">
                  <tbody>{boxessectionCards}</tbody>
                </table>
              </Collapsible>
            </div>
            <Collapsible
              trigger="قهوة مثلجة"
              triggerStyle={{
                fontSize: "40px",
                height: "75px",
                textAlign: "right",
                paddingRight: "30px",
                verticalAlign: "middle"
              }}
              triggerClassName="mb-3 collapse-title"
              triggerOpenedClassName="opened-title"
              triggerTagName="div"
            >
              <table className="table table-hover table-rtl">
                <tbody>{icedcoffeeCards}</tbody>
              </table>
            </Collapsible>

            <div key="cartButton" className="row ">
              <Link
                className="btn btn-outline-light btn-lg mx-auto mb-5"
                to="/cart"
              >
                <FontAwesomeIcon icon={faShoppingCart} />
                {" Cart "}
                <span className="badge badge-light" />
              </Link>
            </div>
          </div>

          <div className="card col-lg-2 col-md-3  category_card float-right mr-4">
            <ul className="list-group list-group-flush">
              <li className="list-group-item text-right">
                <a href="#top">٢ في ١</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_icecream}>ايس كريم</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_juicebags}>اكياس العصائر</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_crepe}>كريب</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_dishes}>الاطباق</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_energy}>مشروبات الطاقة</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_fresh}>عصائر طازجه</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_frozenjuices}>عصائر مثلجة</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_frozenyo}>الروب المثلج</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_halaw}>حلاو</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_hot}>المشروبات الحارة</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_juicebagsmixes}>اكياس عصير الخلطات</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_lotus}>لوتس</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_milkshake}>ملك شيك</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_mixture}>الخلطات</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_molten}>المولتن</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_parties}>الحفلات</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_softdrinks}>اكياس المشروبات الغازية</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_milkshakesbags}>اكياس الميلك شيك</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_pancakes}>بان كيك</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_boxessection}>قسم البوكسات</a>
              </li>
              <li className="list-group-item text-right">
                <a href={"#" + id_incedcoffee}>قهوة مثلجة</a>
              </li>
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1 className="black-title">Loading!!!</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart.cart,
    items: state.items.items,
    profile: state.auth.profile
  };
};
const mapDispatchToProps = dispatch => ({
  createOrder: (order, history) =>
    dispatch(actionCreators.createOrder(order, history))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemList);
