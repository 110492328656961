import jwt_decode from "jwt-decode";

import * as actionTypes from "./actionTypes";

import { fetchItems, fetchVarients } from "./itemsActions";
import { fetchCustomers } from "./customersActions";
import { fetchOrdersDay, fetchOrders } from "./cartActions";
import { fetchStock, fetchStockOrders } from "./stockCartActions";
import instance from "./instance";

const setAuthToken = token => {
  if (token) {
    localStorage.setItem("token", token);
    instance.defaults.headers.common.Authorization = `jwt ${token}`;
  } else {
    localStorage.removeItem("token");
    delete instance.defaults.headers.common.Authorization;
  }
};
export const checkForExpiredToken = () => {
  return async dispatch => {
    // Get token
    const token = localStorage.token;

    if (token) {
      const currentTime = Date.now() / 1000;

      // Decode token and get user info
      const user = jwt_decode(token);

      // Check token expiration
      if (user.exp >= currentTime) {
        // Set auth token header
        await setAuthToken(token);
        // Set user
        dispatch(setCurrentUser(user));
      } else {
        dispatch(logout());
      }
    }
  };
};

export const login = (userData, history) => {
  return dispatch => {
    instance
      .post(`api/login/`, userData)
      .then(res => res.data)
      .then(user => {
        const decodedUser = jwt_decode(user.token);
        setAuthToken(user.token);
        dispatch(setCurrentUser(decodedUser));
      })
      .then(() => history.push("/home"))
      .catch(err => console.error(err.response));
  };
};
//127.0.0.1:8000
export const signup = (userData, history) => {
  return dispatch => {
    instance
      .post(`api/signup/`, userData)
      .then(res => res.data)
      .then(() => dispatch(login(userData)))
      .then(() => history.push("/home"))
      .catch(err => console.error(err.response));
  };
};

export const logout = () => {
  setAuthToken();
  return setCurrentUser();
};

// const setCurrentUser = user => {
//   return dispatch => {
//     dispatch({ type: actionTypes.SET_CURRENT_USER, payload: user });
//     if (user) dispatch(fetchProfile());
//   };
// };

const setCurrentUser = user => {
  return dispatch => {
    if (user) {
      dispatch({ type: actionTypes.SET_CURRENT_USER, payload: user });
      dispatch(fetchProfile());
      dispatch(fetchCustomers());
      dispatch(fetchItems());
      dispatch(fetchVarients());
      dispatch(fetchOrders());
      dispatch(fetchOrdersDay());
      // dispatch(fetchOrdersMonth());
      dispatch(fetchStock());
      dispatch(fetchStockOrders());
      // dispatch(fetchGroceries());
      // dispatch(fetchSpendings());
      // dispatch(fetchInvoices());
    } else {
      dispatch({ type: actionTypes.LOGOUT_USER, payload: null });
    }
  };
};

export const fetchProfile = () => {
  return dispatch => {
    instance
      .get(`api/profile/`)
      .then(res => res.data)
      .then(profile =>
        dispatch({ type: actionTypes.FETCH_PROFILE, payload: profile })
      )
      .catch(err => {
        console.error(err.response);
      });
  };
};
