import React, { Component } from "react";
import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

class OrderItemRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const orderItem = this.props.orderItem;

    const varient = this.props.varients.find(
      varient => varient.id === orderItem.itemVarient.id
    );
    let addOns = orderItem.addOns.map(addOn => addOn.name + ", ");
    const item = this.props.items.find(item => item.id === varient.item);
    if (orderItem && varient && item) {
      return (
        <tr className="tableMid">
          <td>
            <h4 className="border-0 text-dark">{item.name}</h4>
            {varient.varient}- {addOns}
          </td>
          <td>{orderItem.quantity}</td>
          <td>{orderItem.total.toFixed(3)}</td>
          <td>{(orderItem.total * orderItem.quantity).toFixed(3)}</td>
          <td>{orderItem.notes}</td>
        </tr>
      );
    } else {
      return (
        <tr className="tableMid text-center">
          <h1>Loading</h1>
        </tr>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    varients: state.items.varients,
    items: state.items.items
  };
};
const mapActionToState = dispatch => {
  return {
    deleteOrderItem: orderitem_id =>
      dispatch(actionCreators.deleteOrderItem(orderitem_id)),
    updateOrderItem: (orderItem, history) =>
      dispatch(actionCreators.updateOrderItem(orderItem, history))
  };
};
export default connect(
  mapStateToProps,
  mapActionToState
)(OrderItemRow);
