// Types
import * as actionTypes from "./actionTypes";
import instance from "./instance";
export const createCustomer = (customer, history) => {
  return dispatch => {
    instance
      .post("api/customers/create/", customer)
      .then(res => res.data)
      .then(customer => {
        dispatch({
          type: actionTypes.ADD_CUSTOMER,
          payload: customer
        });
      })
      .then(() => history.push("/neworder"))
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const selectCustomer = customer => {
  return dispatch =>
    dispatch({
      type: actionTypes.SELECT_CUSTOMER,
      payload: customer
    });
};

export const fetchCustomers = () => {
  return dispatch => {
    instance
      .get("api/customers/")
      .then(res => res.data)
      .then(customers => {
        dispatch({
          type: actionTypes.FETCH_CUSTOMERS,
          payload: customers
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const updateCustomer = (customer, history) => {
  return dispatch => {
    instance
      .put(`api/customers/${customer.id}/update/`, {
        name: customer.name,
        phoneNo: customer.phoneNo,
        phoneNo2: customer.phoneNo2,
        area: customer.area,
        block: customer.block,
        street: customer.street,
        jaddah: customer.jaddah,
        house_building: customer.house_building,
        floor: customer.floor,
        appartment: customer.appartment,
        extra_directions: customer.extra_directions
      })
      .then(res => res.data)
      .then(() => {
        dispatch({
          type: actionTypes.UPDATE_CUSTOMER,
          payload: customer
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};
