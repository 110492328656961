import * as actionTypes from "./actionTypes";
import qs from "qs";
import instance from "./instance";

export const fetchReport = (range, history) => {
  return dispatch => {
    instance
      .post("reports/daily/", qs.stringify(range))
      .then(res => res.data)
      .then(details => {
        dispatch({ type: actionTypes.FETCH_REPORT, payload: details });
      })
      .then(() =>
        history.push({ pathname: "/dailyreport", state: { range: range } })
      )
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const fetchDailyReport = (branchName, range, history) => {
  return dispatch => {
    instance
      .post(`reports/branch/${branchName}/`, qs.stringify(range))
      .then(res => res.data)
      .then(details => {
        dispatch({ type: actionTypes.FETCH_DAILY_REPORT, payload: details });
      })
      .then(() =>
        history.push({
          pathname: "/dailyclose",
          state: { range: range, branch: branchName }
        })
      )
      .catch(err => {
        console.log(err.response);
      });
  };
};
