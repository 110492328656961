import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
// Actions
import * as actionCreators from "../../store/actions";

class WaiterOrderRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.order.id,
      status: this.props.order.status,
      source: this.props.order.source,
      branch: this.props.order.branch,
      payment: this.props.order.payment,
      driver: this.props.order.driver,
      customer: this.props.order.customer.id,
      deliveryCharges: this.props.order.deliveryCharges
    };
    this.handleProccess = this.handleProccess.bind(this);
    this.handleDelivery = this.handleDelivery.bind(this);
  }

  async handleProccess() {
    await this.setState({ status: "Proccessing" });
    this.props.updateOrder(this.state, this.props.history);
  }
  async handleDelivery() {
    await this.setState({ status: "Delivered" });
    this.props.updateOrder(this.state, this.props.history);
  }

  getTextColor(status) {
    switch (status) {
      case "Cart":
        return "text-dark";

      case "Ordered":
        return "text-primary";

      case "Proccessing":
        return "text-warning";
      case "Out For Delivery":
        return "text-info";
      case "Delivered":
        return "text-success";
      case "Cancelled":
        return "text-danger";
      case "Dine In":
        return "text-secondary";
      default:
      // code block
    }
  }

  render() {
    const order = this.props.order;
    if (order.id) {
      let date =
        order.date.slice(8, 10) +
        "-" +
        order.date.slice(5, 7) +
        "-" +
        order.date.slice(0, 4);
      let time = order.date.slice(11, 19);

      let OrderUpdateCol = (
        <FontAwesomeIcon
          className="text-success "
          size="lg"
          icon={faCheckCircle}
        />
      );
      if (this.state.status === "Ordered") {
        OrderUpdateCol = (
          <button
            onClick={() => this.handleProccess()}
            className="btn btn-outline-warning"
          >
            <FontAwesomeIcon icon={faUtensils} />
          </button>
        );
      } else if (this.state.status === "Proccessing") {
        OrderUpdateCol = (
          <button
            onClick={() => this.handleDelivery()}
            className="btn btn-outline-success"
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
        );
      }

      return (
        <tr>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order
                }
              }}
            >
              {order.id}
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order
                }
              }}
            >
              {time}
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order
                }
              }}
            >
              {date}
            </Link>
          </td>
          <td>
            <Link
              className={this.getTextColor(this.state.status)}
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order
                }
              }}
            >
              {this.state.status}
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order
                }
              }}
            >
              {order.branch}
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order
                }
              }}
            >
              {order.driver}
            </Link>
          </td>
          <td>
            <Link
              className="text-dark"
              to={{
                pathname: "/orderdetail",
                state: {
                  order: order
                }
              }}
            >
              {(
                parseFloat(order.total) + parseFloat(order.deliveryCharges)
              ).toFixed(3)}
              KWD
            </Link>
          </td>
          <td className="text-center">{OrderUpdateCol}</td>
        </tr>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  customers: state.cust.customers
});

const mapDispatchToProps = dispatch => ({
  updateOrder: (order, history) =>
    dispatch(actionCreators.updateOrder(order, history))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WaiterOrderRows);
