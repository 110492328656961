import React, { Component } from "react";
// Components
import OrderItemTable from "./OrderItemTable";

import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";

class Receipt extends Component {
  print() {
    var input = document.getElementById("divToPrint");
    var WinPrint = window.open(
      "",
      "",
      "left=0,top=0, width=800, height=900, scro;;bars=0, status=0"
    );
    WinPrint.document.write(input.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  }
  render() {
    const order = this.props.location.state.order;
    if (order) {
      return (
        <div className="card col-lg-2 col-sm-12  m-auto p-0">
          <div className="card-body" id="divToPrint">
            <div className="text-center" style={{ textAlign: "center" }}>
              <img
                src="https://i.ibb.co/HryD5Fs/logo.png"
                alt="logo"
                width="80px"
              />
            </div>
            <div className="row">
              <div className="col-12 text-right" style={{ textAlign: "right" }}>
                <p className="mb-0">سمبلي جوس</p>
                <p className="mb-0 sm-font-size">هاتف : ١٨١١١٢١</p>
                <p className="mb-0 sm-font-size">
                  contact@simplyjuiceq8.com : البريد الالكتروني
                </p>
              </div>
            </div>
            <hr className="mb-0" />

            <div className="row">
              <div
                className="col-12 text-right mt-2"
                style={{ textAlign: "right" }}
              >
                <span className="sm-font-size col-5 pr-0">
                  <span className="font-weight-bold"> تاريخ الطلب: </span>
                  {order.date.slice(0, 10)}
                </span>
                <span className="sm-font-size col-5 p-0">
                  <span className="font-weight-bold"> وقت الطلب: </span>
                  {order.date.slice(11, 16)}
                </span>
                <br />
                <span className="sm-font-size col-5 p-0 mr-5 pr-2">
                  <span className="font-weight-bold">فاتورة: </span>
                  {order.id}
                </span>
                <span className="sm-font-size col-5 p-0">
                  {order.payment}
                  <span className="font-weight-bold"> :طريقة الدفع </span>
                </span>
              </div>
            </div>

            <hr className="mb-0" />

            {order.customer.name !== "None" ? (
              <div className="row">
                <div
                  className="col-12 text-right mt-2"
                  style={{ textAlign: "right" }}
                >
                  <p
                    className="sm-font-size font-weight-bold mb-0 text-center"
                    style={{ textAlign: "center" }}
                  >
                    العميل{" "}
                  </p>
                  <p className="sm-font-size mb-0">
                    <span className="font-weight-bold">الاسم: </span>
                    {order.customer.name}
                  </p>
                  <span className="sm-font-size col-5 p-0">
                    <span className="font-weight-bold"> رقم تلفون ١ : </span>
                    {order.customer.phoneNo}
                  </span>
                  <span className="sm-font-size col-5 p-0 mr-3">
                    <span className="font-weight-bold"> رقم تلفون ٢ : </span>
                    {order.customer.phoneNo2 ? order.customer.phoneNo2 : "٠"}
                  </span>

                  <span className="sm-font-size col-12 p-0">
                    <span className="font-weight-bold"> المنطقة: </span>
                    {order.customer.area}
                  </span>
                  <br />
                  <span className="sm-font-size col-3 p-0">
                    <span className="font-weight-bold"> قطعة: </span>
                    {order.customer.block}
                  </span>
                  <span className="sm-font-size col-3 p-0 mr-4">
                    <span className="font-weight-bold"> شارع: </span>
                    {order.customer.street}
                  </span>
                  <span className="sm-font-size col-3 p-0 mr-4">
                    <span className="font-weight-bold"> منزل: </span>
                    {order.customer.house_building}
                  </span>
                  <br />
                  <span className="sm-font-size col-3 p-0">
                    <span className="font-weight-bold"> جادة: </span>
                    {order.customer.jaddah ? order.customer.jaddah : "0"}
                  </span>
                  <span className="sm-font-size col-3 p-0 mr-4">
                    <span className="font-weight-bold"> طابق: </span>
                    {order.customer.floor ? order.customer.floor : "0"}
                  </span>
                  <span className="sm-font-size col-3 p-0 mr-4">
                    <span className="font-weight-bold"> شقة: </span>
                    {order.customer.appartment
                      ? order.customer.appartment
                      : "0"}
                  </span>
                  <br />
                  <span className="sm-font-size col-12 p-0">
                    <span className="font-weight-bold"> تعليمات إضافية: </span>
                    {order.customer.extra_directions
                      ? order.customer.extra_directions
                      : "٠"}
                  </span>
                </div>
                <hr className="mb-0" />
              </div>
            ) : (
              <div />
            )}

            <OrderItemTable
              order={order}
              deliveryCharges={order.deliveryCharges}
            />
          </div>
          <button
            className="btn btn-success mx-auto mb-2 "
            onClick={this.print}
          >
            طباعه
          </button>
        </div>
      );
      // } else {
      //   return this.props.history.push("/home");
      // }
    } else {
      return (
        <div>
          <h1 className="black-title">Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  orderItems: state.cart.cartItems,
  cart: state.cart.cart,
  customers: state.cust.customers,
  loading: state.cust.loading,
  customer: state.cust.customer
});
const mapDispatchToProps = dispatch => ({
  selectCustomer: customer => dispatch(actionCreators.selectCustomer(customer)),
  updateOrder: (order, history) =>
    dispatch(actionCreators.updateOrder(order, history)),
  fetchProfile: () => dispatch(actionCreators.fetchProfile())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Receipt);
