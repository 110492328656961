import React, { Component } from "react";

// Components
import OrderTable from "../Order/OrderTable";

import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";
import SearchBar from "./SearchBar";

class orderTrackingcc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: this.props.orders
    };
  }

  filter = async query => {
    let newList = this.props.orders.filter(order => {
      let phoneNos = "";
      if (order.customer.id !== 1) {
        if (order.customer.phoneNo) {
          phoneNos = phoneNos + order.customer.phoneNo.toString() + " ";
        }
        if (order.customer.phoneNo2) {
          phoneNos += order.customer.phoneNo2;
        }
        if (phoneNos.includes(query.toLowerCase())) {
          return order;
        }
      }
      return null;
    });
    this.setState({ filteredList: newList });
  };
  componentDidMount() {
    if (this.props.orders) {
      this.setState({ filteredList: this.props.orders });
    }
  }
  componentDidUpdate(prevState) {
    if (prevState.orders !== this.props.orders) {
      this.setState({ filteredList: this.props.orders });
    }
  }
  render() {
    if (this.props.orders && this.props.profile) {
      let branchOrders = this.state.filteredList.filter(
        order => order.profile === this.props.profile.id
      );
      let mangafOrders = this.state.filteredList.filter(
        order => order.branch === "Mangaf"
      );
      let fintasOrders = this.state.filteredList.filter(
        order => order.branch === "Fintas 1"
      );
      let fintas2Orders = this.state.filteredList.filter(
        order => order.branch === "Fintas 2"
      );
      let sulaibiyaOrders = this.state.filteredList.filter(
        order => order.branch === "Sulaibiya"
      );

      return (
        <div className="card col-11 m-auto p-0">
          <SearchBar filter={this.filter} />
          <div>
            <h1 className="card-header bg-light text-dark text-center">
              {" "}
              سجل طلباتي{" "}
            </h1>
            <div className="card-body">
              {branchOrders.length !== 0 ? (
                <OrderTable orders={branchOrders} />
              ) : (
                <h4 className="text-center text-danger"> No Orders </h4>
              )}
            </div>
          </div>

          <div>
            <h1 className=" card-header bg-light text-dark text-center">
              سجل طلبات فرع الفنطاس ١{" "}
            </h1>
            <div className="card-body">
              {fintasOrders.length !== 0 ? (
                <OrderTable orders={fintasOrders} />
              ) : (
                <p> No Orders </p>
              )}
            </div>
          </div>

          <div>
            <h1 className=" card-header bg-light text-dark text-center">
              {" "}
              سجل طلبات فرع صليبيه{" "}
            </h1>
            <div className="card-body">
              {sulaibiyaOrders.length !== 0 ? (
                <OrderTable orders={sulaibiyaOrders} />
              ) : (
                <p> No Orders </p>
              )}
            </div>
          </div>

          <div>
            <h1 className=" card-header bg-light text-dark text-center">
              {" "}
              سجل طلبات فرع الفنطاس ٢{" "}
            </h1>
            <div className="card-body">
              {fintas2Orders.length !== 0 ? (
                <OrderTable orders={fintas2Orders} />
              ) : (
                <p> No Orders </p>
              )}
            </div>
          </div>

          <div>
            <h1 className=" card-header bg-light text-dark text-center">
              {" "}
              طلبات فرع المنقف{" "}
            </h1>
            <div className="card-body">
              {mangafOrders.length !== 0 ? (
                <OrderTable orders={mangafOrders} />
              ) : (
                <p> No Orders </p>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  orderItems: state.cart.cartItems,
  cart: state.cart.cart,
  customers: state.cust.customers,
  orders: state.cart.orders,
  profile: state.auth.profile
});
const mapDispatchToProps = dispatch => ({
  fetchProfile: () => dispatch(actionCreators.fetchProfile())
});

export default connect(mapStateToProps, mapDispatchToProps)(orderTrackingcc);
