import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

class PromtDate extends Component {
  state = {
    allStockOrders: true,
    startDate: new Date(),
    endDate: new Date(),
    startHour: 0,
    startMinute: 0,
    endHour: 0,
    endMinute: 0
  };

  componentDidMount() {}

  handleChangeStart = date => {
    this.setState({
      startDate: date,
      allStockOrders: false
    });
  };

  handleChangeEnd = date => {
    this.setState({
      endDate: date,
      allStockOrders: false
    });
  };

  clearDateFilter = () => {
    this.setState({
      allStockOrders: true
    });
  };

  changeHandler = e => {
    if (e.target.value.toString() === "") {
      this.setState({ [e.target.name]: 0 });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleSubmit = () => {
    let range = {
      start_date: `${this.state.startDate.getFullYear()}-${this.state.startDate.getMonth() +
        1}-${this.state.startDate.getDate()}`,
      end_date: `${this.state.endDate.getFullYear()}-${this.state.endDate.getMonth() +
        1}-${this.state.endDate.getDate()}`,
      start_hour: parseInt(this.state.startHour),
      end_hour: parseInt(this.state.endHour)
    };
    this.props.fetchReport(range, this.props.history);
  };
  render() {
    return (
      <div className="card col-11 m-auto">
        <div className="card-body">
          <div className="row">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">Filter By Date</span>
              </div>
              <DatePicker
                className="form-control"
                selected={this.state.startDate}
                onChange={this.handleChangeStart}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
              <div className="input-group-prepend">
                <span className="input-group-text">To</span>
              </div>

              <DatePicker
                className="form-control"
                selected={this.state.endDate}
                onChange={this.handleChangeEnd}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
              <div
                className="input-group-prepend"
                onClick={this.clearDateFilter}
              >
                <button className="input-group-text btn btn-warning">
                  Clear Dates
                </button>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">Time:</span>
              </div>
              <div className="input-group-prepend">
                <span className="input-group-text">From:</span>
              </div>
              <input
                className=" form-control col-lg-3 col-md-3 col-sm-8"
                type="search"
                name="startHour"
                placeholder="من الساعه"
                onChange={e => this.changeHandler(e)}
              />
              <div className="input-group-prepend">
                <span className="input-group-text">To:</span>
              </div>
              <input
                className=" form-control col-lg-3 col-md-3 col-sm-8"
                type="search"
                name="endHour"
                placeholder="الى الساعه"
                onChange={e => this.changeHandler(e)}
              />
            </div>
          </div>
          <div className="row">
            <button
              className="btn btn-success  col-lg-3 col-md-6 col-sm-11 m-auto "
              onClick={this.handleSubmit}
            >
              Results
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  fetchProfile: () => dispatch(actionCreators.fetchProfile()),

  fetchReport: (range, history) =>
    dispatch(actionCreators.fetchReport(range, history))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromtDate);
