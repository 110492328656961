import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";

class StockList extends Component {
  render() {
    return (
      <div className=" card border-0 p-3 mt-3 ">
        <table className="mt-3 table">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Category</th>
              <th>Quantity</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody />
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stock: state.stock
});
const mapDispatchToProps = dispatch => ({
  fetchStock: () => dispatch(actionCreators.fetchStock())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockList);
