import React, { Component } from "react";
import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

class StockItemRow extends Component {
  render() {
    const { stockItem } = this.props;
    return (
      <tr>
        <td>{stockItem.name}</td>
        <td>{stockItem.category}</td>
        <td>{stockItem.stock}</td>
        <td>
          Price per unit: {stockItem.price}KD
          <br />
          Total Price: {stockItem.price * stockItem.stock}KD
        </td>
      </tr>
    );
  }
}

const mapStateToProps = state => ({
  stockOrders: state.stockCart.orders
});
const mapDispatchToProps = dispatch => ({
  updateStockOrderItem: item =>
    dispatch(actionCreators.updateStockOrderItem(item))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockItemRow);
