import React, { Component } from "react";

// Components
import OrderTable from "../Order/OrderTable";

import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

class orderTrackingb extends Component {
  componentDidMount() {}
  render() {
    if (this.props.orders && this.props.profile) {
      let branchOrders = this.props.orders.filter(
        order => order.profile === this.props.profile.id
      );

      return (
        <div className="card col-11 m-auto">
          <div className="card-body">
            <h1 className="text-black text-center "> طلبات الفرع </h1>
            {branchOrders.lenghth !== 0 ? (
              <OrderTable orders={branchOrders} />
            ) : (
              <p> No Orders </p>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  orderItems: state.cart.cartItems,
  cart: state.cart.cart,
  customers: state.cust.customers,
  orders: state.cart.orders,
  profile: state.auth.profile
});
const mapDispatchToProps = dispatch => ({
  fetchProfile: () => dispatch(actionCreators.fetchProfile())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(orderTrackingb);
