import * as actionCreators from "../../store/actions";

import React, { Component } from "react";

// Components
import CartTable from "./CartTable";
import { connect } from "react-redux";
import logo from "../../images/logo.png";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      source: "",
      branch: "",
      payment: "",
      driver: "",
      customer: {},
      customers: this.props.customers,
      customerSearch: "",
      discount: 0.0,
      deliveryCharges: 0.0,
    };

    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount() {
    if (this.props.cart) {
      this.setState({
        status: "Cart",
        source: this.props.cart.source,
        branch: this.props.cart.branch,
        payment: this.props.cart.payment,
        driver: this.props.cart.driver,
        customer: this.props.customer,
        customers: this.props.customers,
        customerSearch: "",
        discount: this.props.cart.discount,
        deliveryCharges: this.props.cart.deliveryCharges,
      });
    } else {
      this.props.history.push("/home");
    }
  }

  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitHandler() {
    this.props.createOrder({
      profile: this.props.profile,
      status: this.state.status,
      source: this.state.source,
      branch: this.state.branch,
      payment: this.state.payment,
      driver: this.state.driver,
      customer: this.state.customer.id,
      discount: this.state.discount,
      deliveryCharges: this.state.deliveryCharges,
    });
  }

  setDelivery(charges) {
    this.setState({ deliveryCharges: parseFloat(charges.target.value) });
  }
  setDiscount(charges) {
    this.setState({ discount: parseFloat(charges.target.value) });
  }

  handleCustomers(customer) {
    this.props.selectCustomer(customer);
    this.setState({ customer: customer });
  }

  setBranch(branch) {
    this.setState({ branch: branch });
  }

  setDriver(driver) {
    this.setState({ driver: driver });
  }

  setPayment(payment) {
    this.setState({ payment: payment });
  }

  setSource(source) {
    this.setState({ source: source });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cart !== this.props.cart) {
      this.setState({
        status: "Cart",
        source: this.props.cart.source,
        branch: this.props.cart.branch,
        payment: this.props.cart.payment,
        driver: this.props.cart.driver,
        customer: this.props.order.customer,
        customers: this.props.customers,
      });
    }
  }

  async submitOrder() {
    let ordertemp = this.props.cart;
    ordertemp.id = this.props.cart.id;
    ordertemp.status = "Ordered";
    ordertemp.source = this.state.source;
    ordertemp.branch = this.state.branch;
    ordertemp.payment = this.state.payment;
    ordertemp.driver = this.state.driver;
    ordertemp.customer = this.state.customer.id;
    ordertemp.discount = this.state.discount;
    ordertemp.deliveryCharges = this.state.deliveryCharges;
    let total = 0.0;
    this.props.cart.orderItems.forEach(
      (order) => (total += order.quantity * order.itemVarient.price)
    );
    total = parseFloat(total) + parseFloat(this.props.cart.deliveryCharges);
    if (this.props.profile.position !== "Cashier") {
      await this.props.updateOrder(
        {
          id: this.props.cart.id,
          status: "Ordered",
          source: this.state.source,
          branch: this.state.branch,
          payment: this.state.payment,
          driver: this.state.driver,
          customer: this.state.customer.id,
          discount: this.state.discount,
          deliveryCharges: this.state.deliveryCharges,
          // total: total
        },
        this.props.history
      );
    } else {
      ordertemp.status = "Delivered";
      await this.props.updateOrder(
        {
          id: this.props.cart.id,
          status: "Delivered",
          source: this.state.source,
          branch: this.state.branch,
          payment: this.state.payment,
          driver: this.state.driver,
          customer: this.state.customer.id,
          discount: this.state.discount,
          deliveryCharges: this.state.deliveryCharges,
          // total: total
        },
        this.props.history
      );
    }
    this.props.history.push({
      pathname: "/orderdetail",
      state: {
        order: ordertemp,
      },
    });
  }

  receipt() {
    let ordertemp = this.props.cart;
    ordertemp.id = this.props.cart.id;
    ordertemp.status = "Ordered";
    ordertemp.source = this.state.source;
    ordertemp.branch = this.state.branch;
    ordertemp.payment = this.state.payment;
    ordertemp.driver = this.state.driver;
    ordertemp.customer = this.state.customer.id;
    ordertemp.discount = this.state.discount;
    ordertemp.deliveryCharges = this.state.deliveryCharges;
    if (this.props.profile.position === "Cashier") {
      ordertemp.status = "Delivered";
    }
    this.props.history.push({
      pathname: "/receipt",
      state: {
        order: ordertemp,
      },
    });
  }

  render() {
    if (this.props.cart && !this.props.loading) {
      let cart = this.props.cart;
      if (!this.props.user) {
        this.props.history.push("/home");
      }
      // Used to search customers
      let customers = this.props.customers.filter((customer) => {
        let phonenumbers =
          String(customer.phoneNo) + " " + String(customer.phoneNo2);
        if (phonenumbers.includes(String(this.state.customerSearch))) {
          return customer;
        } else {
          return null;
        }
      });
      //Used for the dropdown list of customers
      let showedCustomers = customers.map((customer) => (
        <li role="presentation">
          <a
            role="menuitem"
            tabIndex="-1"
            key={customer.id}
            onClick={() => this.handleCustomers(customer)}
          >
            {customer.name} {customer.phoneNo} {customer.phoneNo2}{" "}
            {customer.area}
          </a>
        </li>
      ));

      // List of branches that will be used to set the state
      let branches = ["Mangaf", "Fintas 1", "Fintas 2", "Sulaibiya"];
      // Dropdown list of branches
      let branchlist = branches.map((branch) => (
        <a
          className="dropdown-item"
          key={branch}
          onClick={() => this.setBranch(branch)}
        >
          {branch}
        </a>
      ));

      //List of Payment type that is corresponding the values in the backend
      let payments = ["Cash", "Knet"];
      //Dropdown list of payments
      let paymentlist = payments.map((payment) => (
        <a
          className="dropdown-item"
          key={payment}
          onClick={() => this.setPayment(payment)}
        >
          {payment}
        </a>
      ));

      //List of sources
      let sources = [
        "Call",
        "Website",
        "Talabat",
        "Delivero",
        "Carriage",
        "Cravez",
        "Restaurant",
        "Waiter",
      ];
      //Dropdown list of sources
      let sourcelist = sources.map((source) => (
        <a
          className="dropdown-item"
          key={source}
          onClick={() => this.setSource(source)}
        >
          {source}
        </a>
      ));
      let drivers = [
        "None",
        "Qassim",
        "Faheem",
        "Jifan",
        "Shasika",
        "Erfan",
        "Haider",
        "Bandar",
        "Arsan",
        "Fawzar",
        "Saddeeq",
        "Refas",
        "Rawan",
        "Rafenda",
        "Rakshan",
        "AliHassan",
        "MohammedOmair",
      ];
      let driverlist = drivers.map((driver) => (
        <a
          className="dropdown-item"
          key={driver}
          onClick={() => this.setDriver(driver)}
        >
          {driver}
        </a>
      ));
      return (
        <div className="card col-11 m-auto p-0">
          <div className="card-body">
            <h1 className="card-title text-center"> تفاصيل الطلب </h1>
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 text-right">
                <p>سمبلي جوس</p>
                <p>هاتف : ١٨١١١٢١</p>
                <p>contact@simplyjuiceq8.com : البريد الالكتروني</p>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 text-right">
                <img
                  className="mx-auto"
                  alt="simply logo"
                  src={logo}
                  width="150px"
                />
              </div>
            </div>
            <table className="table table-bordered table-responsive-sm col-lg-6 col-md-12 float-right">
              <tbody>
                <tr>
                  <td>
                    <button
                      onClick={() => this.props.history.push("/newcustomer")}
                      className="btn btn-outline-warning mx-auto d-inline col-12 mb-1"
                    >
                      اضف عميل جديد
                    </button>
                    <form className="d-inline" autoComplete="off">
                      <input
                        className="form-control d-inline"
                        type="search"
                        name="customerSearch"
                        placeholder="رقم العميل"
                        id="menu1"
                        data-toggle="dropdown"
                        onChange={(e) => this.changeHandler(e)}
                      />
                      <ul
                        className="dropdown-menu"
                        role="menu"
                        aria-labelledby="menu1"
                      >
                        {showedCustomers}
                      </ul>
                    </form>
                    {this.state.customer.name !== "None" ? (
                      <div>
                        <h5 className="text-right">
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td className="text-right size">
                                  {this.state.customer.name}
                                </td>
                                <td className="row-sm size">:الاسم</td>
                              </tr>
                              <tr>
                                <td className="text-right size">
                                  {this.state.customer.phoneNo}
                                </td>
                                <td className="row-sm size">:رقم تلفون ١</td>
                              </tr>
                              <tr>
                                <td className="text-right size ">
                                  {this.state.customer.phoneNo2}
                                </td>
                                <td className="row-sm size">:رقم تلفون ٢</td>
                              </tr>
                              <tr>
                                <td className="text-right size">
                                  {this.state.customer.area}
                                </td>
                                <td className="row-sm size">:المنطقة</td>
                              </tr>
                              <tr>
                                <td className="text-right size">
                                  <address>
                                    {this.state.customer.block} :قطعة
                                    <br />
                                    {this.state.customer.street} :شارع
                                    <br />
                                    {this.state.customer.jaddah} :جادة
                                    <br />
                                    {this.state.customer.house_building} :منزل
                                    <br />
                                    {this.state.customer.floor} :طابق
                                    <br />
                                    {this.state.customer.appartment} :شقة
                                  </address>
                                </td>
                                <td className="row-sm size ">:العنوان</td>
                              </tr>
                              <tr>
                                <td className="text-right size">
                                  {this.state.customer.extra_directions}
                                </td>
                                <td className="row-sm size">:تعليمات إضافية</td>
                              </tr>
                            </tbody>
                          </table>
                        </h5>
                      </div>
                    ) : (
                      <div />
                    )}
                  </td>
                  <th className="text-right align-middle row-sm size">
                    <h3 className="size">العميل</h3>
                  </th>
                </tr>
              </tbody>
            </table>
            <table className="table table-bordered table-responsive-sm col-lg-6 col-md-12">
              <tbody>
                <tr>
                  <td className="text-right">
                    <div className="dropdown mx-auto">
                      <button
                        className="btn btn-light dropdown-toggle col-12"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {this.state.branch}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {branchlist}
                      </div>
                    </div>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">الفرع</h3>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div className="dropdown mx-auto">
                      <button
                        className="btn btn-light dropdown-toggle col-12"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {this.state.driver}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {driverlist}
                      </div>
                    </div>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">السائق</h3>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div className="dropdown mx-auto">
                      <button
                        className="btn btn-light dropdown-toggle col-12"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {this.state.payment}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {paymentlist}
                      </div>
                    </div>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">طريقة الدفع</h3>
                  </th>
                </tr>
                <tr>
                  <td>
                    <input
                      className="form-control "
                      type="number"
                      name="discount"
                      placeholder={this.state.discount}
                      onChange={(e) => this.setDiscount(e)}
                    />
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">الخصم%</h3>
                  </th>
                </tr>
                <tr>
                  <td>
                    <input
                      className="form-control "
                      type="number"
                      name="deliveryCharges"
                      placeholder={this.state.deliveryCharges}
                      onChange={(e) => this.setDelivery(e)}
                    />
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">رسوم التوصيل</h3>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div className="dropdown mx-auto">
                      <button
                        className="btn btn-light dropdown-toggle col-12"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {this.state.source}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {sourcelist}
                      </div>
                    </div>
                  </td>
                  <th className="text-right align-middle row-sm">
                    <h3 className="size">مصدر الطلب</h3>
                  </th>
                </tr>
              </tbody>
            </table>

            <CartTable
              orderItems={cart.orderItems}
              deliveryCharges={this.state.deliveryCharges}
            />
            <div className="text-center">
              <button
                className="btn btn-lg btn-block btn-outline-success"
                style={{ textAligh: "center" }}
                onClick={() => this.submitOrder()}
              >
                تاكيد الطلب
              </button>
              <button onClick={() => this.receipt()} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1 className="black-title">Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  orders: state.cart.orders,
  user: state.auth.user,
  orderItems: state.cart.cartItems,
  oldCart: state.cart.oldCart,
  cart: state.cart.cart,
  customers: state.cust.customers,
  loading: state.cust.loading,
  customer: state.cust.customer,
  profile: state.auth.profile,
});
const mapDispatchToProps = (dispatch) => ({
  selectCustomer: (customer) =>
    dispatch(actionCreators.selectCustomer(customer)),
  updateOrder: (order, history) =>
    dispatch(actionCreators.updateOrder(order, history)),
  fetchProfile: () => dispatch(actionCreators.fetchProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
