import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from "react";

import DatePicker from "react-datepicker";
import OrderRow from "./OrderRow";
import moment from "moment";

class OrderTable extends Component {
  state = {
    allStockOrders: true,
    startDate: new Date(),
    endDate: new Date(),
    startHour: parseInt("00"),
    endHour: 23,
    driver: "",
    payment: "",
  };

  handleChangeStart = (date) => {
    this.setState({
      startDate: date,
      allStockOrders: false,
    });
  };

  handleChangeEnd = (date) => {
    this.setState({
      endDate: date,
      allStockOrders: false,
    });
  };

  clearDateFilter = () => {
    this.setState({
      allStockOrders: true,
    });
  };

  changeHandler = (e) => {
    if (e.target.value.toString() === "") {
      this.setState({ [e.target.name]: 0 });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  filterChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    let filteredOrders = this.props.orders;
    if (this.state.driver) {
      filteredOrders = filteredOrders.filter(
        (order) => order.driver === this.state.driver
      );
    }
    if (this.state.payment) {
      filteredOrders = filteredOrders.filter(
        (order) =>
          order.payment.toLowerCase() === this.state.payment.toLowerCase()
      );
    }

    let drivers = [
      "",
      "None",
      "Qassim",
      "Faheem",
      "Jifan",
      "Shasika",
      "Erfan",
      "Haider",
      "Bandar",
      "Arsan",
      "Fawzar",
      "Saddeeq",
      "Refas",
      "Rawan",
      "Rafenda",
      "Rakshan",
      "AliHassan",
      "MohammedOmair",
    ];
    let driversList = drivers.map((driver) => {
      return (
        <option key={driver} value={driver}>
          {driver}
        </option>
      );
    });

    if (!this.state.allStockOrders) {
      let start = moment(this.state.startDate)
        .second(0)
        .minute(0)
        .hour(parseInt(this.state.startHour));
      let end = moment(this.state.endDate)
        .second(0)
        .minute(0)
        .hour(parseInt(this.state.endHour));
      filteredOrders = filteredOrders.filter((order) => {
        if (moment(order.date).isBetween(start, end)) {
          console.log(moment(order.date).isBetween(start, end));
          return order;
        }
        return null;
      });
    }

    const orders = filteredOrders.map((order) => (
      <OrderRow key={order.id} order={order} />
    ));

    let total = 0.0;
    filteredOrders.forEach((order) => {
      if (order.status === "Delivered" || order.status === "Out For Delivery") {
        total += parseFloat(order.total) + parseFloat(order.deliveryCharges);
      }
    });

    return (
      <div>
        <div className="row">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">Filter By Date</span>
            </div>
            <DatePicker
              className="form-control"
              selected={this.state.startDate}
              onChange={this.handleChangeStart}
              selectsStart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            <div className="input-group-prepend">
              <span className="input-group-text">To</span>
            </div>

            <DatePicker
              className="form-control"
              selected={this.state.endDate}
              onChange={this.handleChangeEnd}
              selectsEnd
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            <div className="input-group-prepend" onClick={this.clearDateFilter}>
              <button className="input-group-text btn btn-warning">
                Clear Dates
              </button>
            </div>
            <div className="input-group-prepend">
              <span className="input-group-text">Driver</span>
            </div>
            <select
              name="driver"
              className="form-control col-lg-3 col-md-4 col-sm-8 mr-md-3 mr-lg-3"
              onChange={this.filterChange}
            >
              {driversList}
            </select>
            <div className="input-group-prepend">
              <span className="input-group-text">Payment</span>
            </div>
            <select
              name="payment"
              className="form-control col-sm-8 col-lg-3 col-md-4 mr-lg-3 mr-md-3"
              onChange={this.filterChange}
            >
              <option value="">----</option>
              <option value="Cash">Cash</option>
              <option value="Knet">Knet</option>
            </select>
          </div>

          <div className="row ml-2">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">From:</span>
              </div>
              <input
                className=" form-control col-lg-3 col-md-3 col-sm-8"
                type="search"
                name="startHour"
                placeholder="من الساعه"
                onChange={(e) => this.changeHandler(e)}
              />
              <div className="input-group-prepend">
                <span className="input-group-text">To:</span>
              </div>
              <input
                className=" form-control col-lg-3 col-md-3 col-sm-8"
                type="search"
                name="endHour"
                placeholder="الى الساعه"
                onChange={(e) => this.changeHandler(e)}
              />
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="mt-3 table">
            <thead>
              <tr>
                <th className="text-success">Order Id</th>
                <th className="text-success">Order Time</th>
                <th className="text-success">Order Date</th>
                <th className="text-success">Order Status</th>
                <th className="text-success">Order Branch</th>
                <th className="text-success">Order Driver</th>
                <th className="text-success">Order Discount</th>
                <th className="text-success">Order Total</th>
                <th className="text-success">Order Payment</th>
              </tr>
            </thead>
            <tbody>{orders}</tbody>
          </table>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 border text-right card-title">
            المجموع:
            {total.toFixed(3)}
            دينار كويتي
          </div>
        </div>
      </div>
    );
  }
}

export default OrderTable;
