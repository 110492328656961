import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Actions
import * as actionCreators from "./store/actions";

// Components
import NavBar from "./components/Navigation/NavBar";
import RegisterOrLogin from "./components/RegisterOrLogin";
import NewOrder from "./components/NewOrder";
import NewCustomer from "./components/NewCustomer";
import ItemList from "./components/ItemList";
import Cart from "./components/Cart/Cart";
import HomePage from "./components/HomePage/HomePage";
import OrderTrackCc from "./components/OrdersTracking/ordersTrackingcc";
import OrderTrackb from "./components/OrdersTracking/ordersTrackingb";
import OrderDetail from "./components/OrderItem/OrderDetail";
import UpdateOrders from "./components/UpdateOrdersInBranch/updateOrders";
import DailyReport from "./components/MonthlyReport/DailyReport";
import PromtDate from "./components/MonthlyReport/PromtDate";
import OrdersTrackingByBranch from "./components/OrdersTracking/OrdersTrackingByBranch";
import EditOrder from "./components/Order/EditOrder";
import StockShoppingList from "./components/Stock/StockShoppingList";
import StockList from "./components/Stock/StockList";
import NewStockOrder from "./components/Stock/NewStockOrder";
import StockCart from "./components/StockCart/StockCart";
import StockOrders from "./components/StockOrders/StockOrders";
import StockOrderDetail from "./components/StockOrders/StockOrderDetail";
import WaiterOrder from "./components/OrdersTracking/WaiterOrder";
import CleanUp from "./components/OrdersTracking/CleanUp";
import EditStockOrder from "./components/Stock/EditStockOrder";
import NewSpending from "./components/Spendings/NewSpending";
import NewInvoice from "./components/Invoices/NewInvoice";
import NewGrocery from "./components/Groceries/newGrocery";
import SpendingsList from "./components/Spendings/SpendingsList";
import GroceriesList from "./components/Groceries/GroceriesList";
import StockOrdersToday from "./components/StockOrders/StockOrdersToday";
import AvailableStockItems from "./components/StockOrders/AvailableStockItems";
import StockOrdersBranch from "./components/StockOrders/StockOrdersBranch";
import StockCleanUp from "./components/StockOrders/StockCleanUp";
import DailyClose from "./components/DailyReport/DailyClose";
import PromtForDaily from "./components/DailyReport/PromtForDaily";
import OrdersTrackingForWaiter from "./components/OrdersTracking/OrdersTrackingForWaiter";
import Receipt from "./components/Receipt/Receipt";

class App extends Component {
  render() {
    return (
      <div className="App">
        <NavBar />
        <Switch>
          <Route path="/neworder/:phone?" component={NewOrder} />
          <Route path="/list" exact component={ItemList} />
          <Route path="/cart" component={Cart} />
          <Route path="/stockcart" exact component={StockCart} />
          <Route path="/login" component={RegisterOrLogin} />
          <Route path="/editorder" component={EditOrder} />
          <Route path="/newcustomer" component={NewCustomer} />
          <Route path="/ordertrackcc" exact component={OrderTrackCc} />
          <Route path="/ordertrackb" exact component={OrderTrackb} />
          <Route path="/ordertrack/branch" component={OrdersTrackingByBranch} />
          <Route path="/orderdetail" exact component={OrderDetail} />
          <Route path="/newstockorder" exact component={NewStockOrder} />
          <Route
            path="/stockorders/available"
            exact
            component={AvailableStockItems}
          />
          <Route path="/stockorders/today" exact component={StockOrdersToday} />
          <Route path="/stockorders" exact component={StockOrders} />
          <Route
            path="/stockordersbranch"
            exact
            component={StockOrdersBranch}
          />
          <Route
            path="/stockorders/:stockOrderID"
            component={StockOrderDetail}
          />
          <Route path="/editstockorder" component={EditStockOrder} />
          <Route path="/stockitems" component={StockShoppingList} />
          <Route path="/stocklist" component={StockList} />
          <Route path="/updateorders" exact component={UpdateOrders} />
          <Route path="/dailyreport" exact component={DailyReport} />
          <Route path="/promtdate" exact component={PromtDate} />

          <Route path="/dailyclose" exact component={DailyClose} />
          <Route path="/promtfordaily" exact component={PromtForDaily} />
          <Route
            path="/waiterordertrack"
            exact
            component={OrdersTrackingForWaiter}
          />
          <Route path="/waiterorder" exact component={WaiterOrder} />
          <Route path="/cleanup" exact component={CleanUp} />
          <Route path="/stockcleanup" exact component={StockCleanUp} />
          <Route path="/newspending" exact component={NewSpending} />
          <Route path="/newinvoice" exact component={NewInvoice} />
          <Route path="/newgrocery" exact component={NewGrocery} />
          <Route path="/home" component={HomePage} />
          <Route path="/spendings" component={SpendingsList} />
          <Route path="/groceries" component={GroceriesList} />
          <Route path="/receipt" component={Receipt} />
          <Redirect to="/login" />
        </Switch>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.auth.user,
  profile: state.prof.profile
});

const mapDispatchToProps = dispatch => ({
  checkToken: () => dispatch(actionCreators.checkForExpiredToken()),
  fetchProfile: user_id => dispatch(actionCreators.fetchProfile(user_id)),
  setCart: orderList => dispatch(actionCreators.setCart(orderList)),
  fetchToday: () => dispatch(actionCreators.fetchOrdersDay())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
