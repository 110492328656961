import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import StockOrderItemsTable from "./StockOrderItemsTable";

class StockOrderDetail extends Component {
  state = {
    stockOrder: {},
    loading: true
  };
  componentDidMount() {
    const stockOrderID = this.props.match.params.stockOrderID;
    let order = this.props.stockItems.find(
      oneOrder => oneOrder.id === parseInt(stockOrderID)
    );
    this.setState({ stockOrder: order, loading: false });
  }

  updateStatus = () => {
    let newStockOrder = { ...this.state.stockOrder };
    newStockOrder.status = "Ordered";

    this.props.updateStockOrder({
      driver: this.state.stockOrder.driver,
      status: "Ordered",
      id: this.state.stockOrder.id
    });
    this.setState({
      stockOrder: {
        ...newStockOrder
      }
    });
  };

  checkStat = status => {
    if (status === "Delivered" || status === "Cancelled") {
      return null;
    } else if (status === "Cart") {
      return (
        <>
          <Link
            to={{
              pathname: "/editstockorder",
              state: {
                order: this.state.stockOrder
              }
            }}
            className="btn btn-dark float-right"
          >
            Edit
          </Link>
          <button
            className="btn btn-warning float-right"
            onClick={this.updateStatus}
          >
            Checkout
          </button>
        </>
      );
    } else
      return (
        <Link
          to={{
            pathname: "/editstockorder",
            state: {
              order: this.state.stockOrder
            }
          }}
          className="btn btn-dark float-right"
        >
          Edit
        </Link>
      );
  };

  render() {
    if (this.state.loading) {
      return <h2>Loading...</h2>;
    }
    const { stockOrder } = this.state;
    let date =
      stockOrder.date.slice(8, 10) +
      "-" +
      stockOrder.date.slice(5, 7) +
      "-" +
      stockOrder.date.slice(0, 4);
    let time = stockOrder.date.slice(11, 19);

    return (
      <div className="container">
        <div className=" card border-0 p-3 mt-3 ">
          <div className="row mt-3">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <td>{stockOrder.id}</td>
                  <th>Branch</th>
                  <td>{stockOrder.branch}</td>
                  <th>Order Status</th>
                  <td>{stockOrder.status}</td>
                  <th>Driver</th>
                  <td>{stockOrder.driver}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Date</th>
                  <td>{date}</td>
                  <th>Time</th>
                  <td>{time}</td>
                  <th>Total</th>
                  <td>{stockOrder.total}KD</td>
                </tr>
              </tbody>
            </table>
          </div>
          {this.checkStat(stockOrder.status)}
        </div>
        <StockOrderItemsTable
          stockItems={stockOrder.stockOrderItems}
          orderStatus={stockOrder.status}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    stockItems: state.stockCart.orders
  };
};

const mapDispatchToProps = dispatch => ({
  fetchStockOrders: () => dispatch(actionCreators.fetchStockOrders()),
  updateStockOrder: order => dispatch(actionCreators.updateStockOrder(order))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockOrderDetail);
