import React, { Component } from "react";

// Components
import GroceryTable from "./GroceryTable";

import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

class GroceriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.fetchGroceries();
  }
  render() {
    if (this.props.groceries && this.props.profile) {
      let mangafGroceries = this.props.groceries.filter(
        order => order.branch === "Mangaf"
      );
      let fintasGroceries = this.props.groceries.filter(
        order => order.branch === "Fintas 1"
      );
      let fintas2Groceries = this.props.groceries.filter(
        order => order.branch === "Fintas 2"
      );

      let sulaibiyaGroceries = this.props.groceries.filter(
        order => order.branch === "Sulaibiya"
      );


      return (
        <div className="card m-auto col-11 p-0">
          <h1 className="card-header text-center">مصروفات المنقف </h1>
          <div className="card-body">
            {mangafGroceries.length !== 0 ? (
              <GroceryTable groceries={mangafGroceries} />
            ) : (
              <p className="text-center text-danger"> No Groceries </p>
            )}
          </div>

          <h1 className="card-header text-center">مصروفات فرع الفنطاس ١ </h1>
          <div className="card-body">
            {fintasGroceries.length !== 0 ? (
              <GroceryTable groceries={fintasGroceries} />
            ) : (
              <p className="text-center text-danger"> No Groceries </p>
            )}
          </div>

          <h1 className="card-header text-center"> مصروفات فرع الفنطاس ٢ </h1>
          <div className="card-body">
            {fintas2Groceries.length !== 0 ? (
              <GroceryTable groceries={fintas2Groceries} />
            ) : (
              <p className="text-center text-danger"> No Groceries </p>
            )}
          </div>

          <h1 className="card-header text-center"> مصروفات فرع صليبيه </h1>
          <div className="card-body">
            {sulaibiyaGroceries.length !== 0 ? (
              <GroceryTable groceries={sulaibiyaGroceries} />
            ) : (
              <p className="text-center text-danger"> No Groceries </p>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  groceries: state.spend.groceries,
  profile: state.auth.profile
});
const mapDispatchToProps = dispatch => ({
  fetchGroceries: () => dispatch(actionCreators.fetchGroceries())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroceriesList);
