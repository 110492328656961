// Actions
import * as actionCreators from "../store/actions";

import React, { Component } from "react";

import { connect } from "react-redux";

class NewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "Cart",
      source: "يرجى اختيار مصدر الطلب",
      branch: "يرجى اختيار الفرع",
      payment: "يرجى اختيار طريقة الدفع",
      driver: "يرجى اختيار السائق ",
      customer: "اختر رقم العميل",
      customers: this.props.customers,
      customerSearch: "",
      discount: 0.0,
      deliveryCharges: "يرجى ادخال رسوم التوصيل",
    };

    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async submitHandler() {
    if (!this.props.user) {
      this.props.history.push("/login");
    }
    if (
      this.state.branch === "يرجى اختيار الفرع" ||
      this.state.customer === "اختر رقم العميل" ||
      this.state.driver === "يرجى اختيار السائق " ||
      this.state.source === "يرجى اختيار مصدر الطلب" ||
      this.state.payment === "يرجى اختيار طريقة الدفع" ||
      this.state.deliveryCharges === "يرجى ادخال رسوم التوصيل"
    ) {
      alert("يرجى التاكد من ادخال جميع البينات");
    } else if (this.props.profile.position === "Waiter") {
      await this.props.createOrder(
        {
          status: this.state.status,
          source: this.state.source,
          branch: this.props.profile.branch,
          payment: this.state.payment,
          driver: "None",
          customer: this.state.customer.id,
          discount: this.state.discount,
          deliveryCharges: 0.0,
        },
        this.props.history
      );
    } else if (this.props.profile.position === "Cashier") {
      await this.props.createOrder(
        {
          status: this.state.status,
          source: "Restaurant",
          branch: this.props.profile.branch,
          payment: this.state.payment,
          driver: "None",
          customer: this.state.customer.id,
          discount: this.state.discount,
          deliveryCharges: 0.0,
        },
        this.props.history
      );
    } else {
      await this.props.createOrder(
        {
          status: this.state.status,
          source: this.state.source,
          branch: this.state.branch,
          payment: this.state.payment,
          driver: this.state.driver,
          customer: this.state.customer.id,
          discount: this.state.discount,
          deliveryCharges: this.state.deliveryCharges,
        },
        this.props.history
      );
    }
  }

  componentDidMount() {
    let phone = this.props.match.params.phone;
    if (phone) {
      this.setState({ customerSearch: phone });
    }
  }

  handleCustomers(customer) {
    this.props.selectCustomer(customer);
    this.setState({ customer: customer });
  }

  setBranch(branch) {
    this.setState({ branch: branch });
  }

  setDriver(driver) {
    this.setState({ driver: driver });
  }

  setPayment(payment) {
    this.setState({ payment: payment });
  }

  setSource(source) {
    this.setState({ source: source });
  }

  setDelivery(charges) {
    this.setState({ deliveryCharges: charges.target.value });
  }

  setDiscount(charges) {
    this.setState({ discount: parseFloat(charges.target.value) });
  }

  render() {
    let customers = this.props.customers.filter((customer) => {
      let phonenumbers =
        String(customer.phoneNo) + " " + String(customer.phoneNo2);
      if (phonenumbers.includes(String(this.state.customerSearch))) {
        return customer;
      }
      return null;
    });
    let showedCustomers = customers.map((customer) => (
      <li role="presentation">
        <a
          role="menuitem"
          tabIndex="-1"
          key={customer.id}
          onClick={() => this.handleCustomers(customer)}
        >
          {customer.name} {customer.phoneNo} {customer.phoneNo2} {customer.area}
        </a>
      </li>
    ));
    if (showedCustomers.length > 20) {
      showedCustomers = showedCustomers.slice(0, 10);
    }

    const type = this.props.match.url.substring(1);
    const { username, password } = this.state;
    let branches = ["Mangaf", "Fintas 1", "Fintas 2", "Sulaibiya"];
    let branchlist = branches.map((branch) => (
      <a
        className="dropdown-item"
        key={branch}
        onClick={() => this.setBranch(branch)}
      >
        {branch}
      </a>
    ));
    let payments = ["Cash", "Knet"];
    let paymentlist = payments.map((payment) => (
      <a
        className="dropdown-item"
        key={payment}
        onClick={() => this.setPayment(payment)}
      >
        {payment}
      </a>
    ));
    let sources = [
      "Call",
      "Website",
      "Talabat",
      "Delivero",
      "Carriage",
      "Cravez",
      "Restaurant",
      "Waiter",
    ];
    let sourcelist = sources.map((source) => (
      <a
        className="dropdown-item"
        key={source}
        onClick={() => this.setSource(source)}
      >
        {source}
      </a>
    ));
    let drivers = [
      "None",
      "Qassim",
      "Faheem",
      "Jifan",
      "Shasika",
      "Erfan",
      "Haider",
      "Bandar",
      "Arsan",
      "Fawzar",
      "Saddeeq",
      "Refas",
      "Rawan",
      "Rafenda",
      "Rakshan",
      "AliHassan",
      "MohammedOmair",
    ];
    let driverlist = drivers.map((driver) => (
      <a
        className="dropdown-item"
        key={driver}
        onClick={() => this.setDriver(driver)}
      >
        {driver}
      </a>
    ));
    return (
      <div className="card col-lg-8 col-md-11 mx-auto p-0 mt-5">
        <div className="card-body">
          <h2 className="text-right">طلب جديد</h2>

          <table className="table table-bordered table-responsive-sm">
            <tbody>
              <tr>
                <td>
                  <button
                    onClick={() =>
                      this.props.history.push({
                        pathname: "/newcustomer",
                        state: { phone: this.state.customerSearch },
                      })
                    }
                    className="btn btn-outline-warning mx-auto d-inline col-12 mb-1"
                  >
                    اضف عميل جديد
                  </button>
                  <form className="d-inline" autoComplete="off">
                    <input
                      className="form-control d-inline"
                      type="search"
                      name="customerSearch"
                      placeholder="رقم العميل"
                      value={this.state.customerSearch}
                      id="menu1"
                      data-toggle="dropdown"
                      onChange={(e) => this.changeHandler(e)}
                    />
                    <ul
                      className="dropdown-menu"
                      role="menu"
                      aria-labelledby="menu1"
                    >
                      {showedCustomers}
                    </ul>
                  </form>
                  {this.state.customer.name ? (
                    <div>
                      <h5 className="text-right">
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td className="text-right">
                                {this.state.customer.name}
                              </td>
                              <td className="row-sm">:الاسم</td>
                            </tr>
                            <tr>
                              <td className="text-right">
                                {this.state.customer.phoneNo}
                              </td>
                              <td className="row-sm">:رقم تلفون ١</td>
                            </tr>
                            <tr>
                              <td className="text-right">
                                {this.state.customer.phoneNo2}
                              </td>
                              <td className="row-sm">:رقم تلفون ٢</td>
                            </tr>
                            <tr>
                              <td className="text-right">
                                {this.state.customer.area}
                              </td>
                              <td className="row-sm">:المنطقة</td>
                            </tr>
                            <tr>
                              <td className="text-right">
                                <address>
                                  {this.state.customer.block} :قطعة
                                  <br />
                                  {this.state.customer.street} :شارع
                                  <br />
                                  {this.state.customer.jaddah} :جادة
                                  <br />
                                  {this.state.customer.house_building} :منزل
                                  <br />
                                  {this.state.customer.floor} :طابق
                                  <br />
                                  {this.state.customer.appartment} :شقة
                                </address>
                              </td>
                              <td className="row-sm">:العنوان</td>
                            </tr>
                            <tr>
                              <td className="text-right">
                                {this.state.customer.extra_directions}
                              </td>
                              <td className="row-sm">:تعليمات إضافية</td>
                            </tr>
                          </tbody>
                        </table>
                      </h5>
                    </div>
                  ) : (
                    <div />
                  )}
                </td>
                <th className="text-right align-middle">
                  <h3>العميل</h3>
                </th>
              </tr>
              <tr>
                <td className="text-right">
                  <div className="dropdown mx-auto">
                    <button
                      className="btn btn-light dropdown-toggle col-12"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.state.branch}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {branchlist}
                    </div>
                  </div>
                </td>
                <th className="text-right align-middle">
                  <h3>الفرع</h3>
                </th>
              </tr>
              <tr>
                <td>
                  <div className="dropdown mx-auto">
                    <button
                      className="btn btn-light dropdown-toggle col-12"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.state.driver}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {driverlist}
                    </div>
                  </div>
                </td>
                <th className="text-right align-middle">
                  <h3>السائق</h3>
                </th>
              </tr>
              <tr>
                <td>
                  <div className="dropdown mx-auto">
                    <button
                      className="btn btn-light dropdown-toggle col-12"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.state.payment}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {paymentlist}
                    </div>
                  </div>
                </td>
                <th className="text-right align-middle">
                  <h3>طريقة الدفع</h3>
                </th>
              </tr>
              <tr>
                <td>
                  <input
                    className="form-control "
                    type="number"
                    name="discount"
                    placeholder={this.state.discount}
                    onChange={(e) => this.setDiscount(e)}
                  />
                </td>
                <th className="text-right align-middle">
                  <h3>الخصم%</h3>
                </th>
              </tr>
              <tr>
                <td>
                  <input
                    className="form-control "
                    type="number"
                    name="deliveryCharges"
                    placeholder={this.state.deliveryCharges}
                    onChange={(e) => this.setDelivery(e)}
                  />
                </td>
                <th className="text-right align-middle">
                  <h3>رسوم التوصيل</h3>
                </th>
              </tr>
              <tr>
                <td>
                  <div className="dropdown mx-auto">
                    <button
                      className="btn btn-light dropdown-toggle col-12"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.state.source}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {sourcelist}
                    </div>
                  </div>
                </td>
                <th className="text-right align-middle">
                  <h3>مصدر الطلب</h3>
                </th>
              </tr>
            </tbody>
          </table>

          <div className="row">
            <button
              className="btn btn-success mx-auto col-lg-8 col-sm-12"
              onClick={() => this.submitHandler()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  profile: state.auth.profile,
  customers: state.cust.customers,
});

const mapDispatchToProps = (dispatch) => ({
  selectCustomer: (customer) =>
    dispatch(actionCreators.selectCustomer(customer)),
  createOrder: (order, history) =>
    dispatch(actionCreators.createOrder(order, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOrder);
