import * as actionCreators from "../../store/actions";

import React, { Component } from "react";

// Components
import OrderTable from "../Order/OrderTable";
import SearchBar from "./SearchBar";
import { connect } from "react-redux";

class OrdersTrackingByBranch extends Component {
  state = {
    filteredList: this.props.orders,
    branchList: this.props.orders,
    payment: "",
    driver: "",
    source: "",
  };

  filterChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    // this.props.fetchOrders();
    if (this.props.orders) {
      let branchOrders = this.props.orders.filter(
        (order) =>
          // order.profile === this.props.profile.id &&
          order.branch === this.props.profile.branch
      );
      this.setState({ filteredList: branchOrders, branchList: branchOrders });
    }
  }

  componentDidUpdate(prevState) {
    if (this.props.orders !== prevState.orders) {
      let branchOrders = this.props.orders.filter(
        (order) =>
          // order.profile === this.props.profile.id &&
          order.branch === this.props.profile.branch
      );
      this.setState({ filteredList: branchOrders, branchList: branchOrders });
    }
  }

  componentWillUnmount() {
    // clearInterval();
  }

  filter = async (query) => {
    let newList = this.state.branchList.filter((order) => {
      let phoneNos = "";
      if (order.customer.id !== 1) {
        if (order.customer.phoneNo) {
          phoneNos = phoneNos + order.customer.phoneNo.toString() + " ";
        }
        if (order.customer.phoneNo2) {
          phoneNos += order.customer.phoneNo2;
        }
        if (phoneNos.includes(query.toLowerCase())) {
          return order;
        }
      }
      return null;
    });
    this.setState({ filteredList: newList });
  };
  render() {
    if (
      this.props.orders &&
      this.props.profile &&
      this.props.profile.position !== "Cashier"
    ) {
      let branchOrders = this.state.filteredList;

      if (this.state.payment) {
        branchOrders = branchOrders.filter(
          (order) => order.payment === this.state.payment
        );
      }
      if (this.state.driver) {
        branchOrders = branchOrders.filter(
          (order) => order.driver === this.state.driver
        );
      }
      if (this.state.source) {
        branchOrders = branchOrders.filter(
          (order) => order.source === this.state.source
        );
      }
      let drivers = [
        "",
        "None",
        "Qassim",
        "Faheem",
        "Jifan",
        "Shasika",
        "Erfan",
        "Haider",
        "Bandar",
        "Arsan",
        "Fawzar",
        "Saddeeq",
        "Refas",
        "Rawan",
        "Rafenda",
        "Rakshan",
        "AliHassan",
        "MohammedOmair",
      ];
      let driversList = drivers.map((driver) => {
        return (
          <option key={driver} value={driver}>
            {driver}
          </option>
        );
      });
      return (
        <div className="card col-11 m-auto">
          <div className="card-body">
            <h1 className="text-center card-title"> طلبات الفرع </h1>
            <div>
              <SearchBar filter={this.filter} />
            </div>
            <form>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Payment</span>
                </div>
                <select
                  name="payment"
                  className="form-control col-sm-8 col-lg-3 col-md-4 mr-lg-3 mr-md-3"
                  onChange={this.filterChange}
                >
                  <option value="">----</option>
                  <option value="Cash">Cash</option>
                  <option value="Knet">Knet</option>
                </select>
                <div className="input-group-prepend">
                  <span className="input-group-text">Driver</span>
                </div>
                <select
                  name="driver"
                  className="form-control col-lg-3 col-md-4 col-sm-8 mr-md-3 mr-lg-3"
                  onChange={this.filterChange}
                >
                  {driversList}
                </select>
                <div className="input-group-prepend">
                  <span className="input-group-text">Source</span>
                </div>
                <select
                  name="source"
                  className="form-control col-lg-3 col-sm-8 col-md-4"
                  onChange={this.filterChange}
                >
                  <option value="">----</option>
                  <option value="Call">Call</option>
                  <option value="Website">Website</option>
                  <option value="Talabat">Talabat</option>
                  <option value="Delivero">Delivero</option>
                  <option value="Carriage">Carriage</option>
                  <option value="Cravez">Cravez</option>
                  <option value="Restaurant">Restaurant</option>
                  <option value="Waiter">Waiter</option>
                </select>
              </div>
            </form>
            {branchOrders.length ? (
              <OrderTable orders={branchOrders} />
            ) : (
              <p className="text-center text-danger"> No Orders </p>
            )}
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                <></>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  orderItems: state.cart.cartItems,
  cart: state.cart.cart,
  customers: state.cust.customers,
  orders: state.cart.orders,
  profile: state.auth.profile,
});
const mapDispatchToProps = (dispatch) => ({
  fetchOrders: () => dispatch(actionCreators.fetchOrders()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersTrackingByBranch);
