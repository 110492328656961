import React, { Component } from "react";
import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";
import StockShoppingItem from "./StockShoppingItem";
import StockTable from "../StockCart/StockTable";

class StockList extends Component {
  componentDidMount() {
    this.props.fetchStock();
  }

  async submitOrder() {
    await this.props.updateStockOrder(
      {
        id: this.props.stock.cart.id,
        status: "Ordered",
        driver: this.props.stock.cart.driver
      },
      this.props.history
    );
    this.props.history.push(`/stockorders/${this.props.stock.cart.id}`);
  }

  render() {
    if (this.props.stock.fetchLoading) {
      return (
        <div>
          <h3>Loading...</h3>
        </div>
      );
    }
    let stockItems = this.props.stock.stockItems
      .filter(stock => stock.available === true)
      .map(stockItem => (
        <StockShoppingItem key={stockItem.id} stockItem={stockItem} />
      ));

    return (
      <div>
        <div className=" card border-0 p-3 mt-3 ">
          <table className="mt-3 table">
            <thead>
              <tr>
                <th>Item Image</th>
                <th>Name</th>
                <th>Description</th>
                <th>Available Stock</th>
                <th>Quantity</th>
                <th>Notes</th>
                <th>Add</th>
              </tr>
            </thead>
            <tbody>{stockItems}</tbody>
          </table>
        </div>
        <div className=" card border-0 p-3 mt-3 ">
          <h2>Stock Cart</h2>
          <StockTable />
          <div className="text-center">
            <button
              className="btn btn-lg btn-block btn-success mx-auto"
              style={{ textAligh: "center", width: "10%" }}
              onClick={() => this.submitOrder()}
            >
              تاكيد الطلب
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stock: state.stockCart
});
const mapDispatchToProps = dispatch => ({
  fetchStock: () => dispatch(actionCreators.fetchStock()),
  updateStockOrder: (stockOrder, history) =>
    dispatch(actionCreators.updateStockOrder(stockOrder, history))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockList);
