import React, { Component } from "react";

// Components
import CleanUpTable from "./CleanUpTable";

import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

class cleanUp extends Component {
  componentDidMount() {}
  render() {
    if (this.props.orders && this.props.profile) {
      let branchOrders = this.props.orders.filter(order => {
        if (this.props.profile.position !== "CallCenter") {
          return (
            order.branch === this.props.profile.branch &&
            order.status === "Cart"
          );
        } else {
          return (
            order.profile === this.props.profile.id && order.status === "Cart"
          );
        }
      });

      return (
        <div className="card col-11 mx-auto">
          <div className="card-body">
            <h1 className="text-center "> الطلبات المعلقة </h1>
            {branchOrders.length !== 0 ? (
              <CleanUpTable orders={branchOrders} />
            ) : (
              <p className="text-center text-danger"> No Orders </p>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  orderItems: state.cart.cartItems,
  cart: state.cart.cart,
  customers: state.cust.customers,
  orders: state.cart.orders,
  profile: state.auth.profile
});
const mapDispatchToProps = dispatch => ({
  fetchProfile: () => dispatch(actionCreators.fetchProfile())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(cleanUp);
