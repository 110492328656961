import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import * as actionCreators from "../../store/actions";

class NewInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockItem: 1,
      invoiceNo: "",
      companyName: "",
      quantity: 0,
      notes: "",
      pricePerItem: 0.0
    };

    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async submitHandler() {
    await this.props.createInvoice(this.state);
    this.props.history.push("/home");
  }

  render() {
    let stockitemsList = this.props.stockItems.map(item => {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });
    return (
      <div className="card col-lg-6 col-md-8 col-sm-11 mx-auto p-0 mt-5">
        <div className="card-body">
          <h2 className="card-title text-right">اضافة للمخزن</h2>
          <form onSubmit={this.submitHandler} noValidate>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">المنتج</span>
              </div>
              <select
                name="stockItem"
                value={this.state.stockItem}
                className="form-control"
                onChange={this.changeHandler}
              >
                {stockitemsList}
              </select>
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="رقم الفاتوره"
                name="invoiceNo"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="اسم الشركة "
                name="companyName"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="number"
                placeholder="الكمية"
                name="quantity"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="الملاحظات"
                name="notes"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="number"
                placeholder="السعر للحبه"
                name="pricePerItem"
                required
                onChange={this.changeHandler}
              />
            </div>
            <button
              className="btn btn-success col-sm-12 float-right"
              onClick={() => this.submitHandler()}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stockItems: state.stockCart.stockItems
});

const mapDispatchToProps = dispatch => ({
  createInvoice: income => dispatch(actionCreators.createInvoice(income))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewInvoice);
