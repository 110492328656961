import React, { Component } from "react";

import UpdateOrdersRow from "./StockCleanUpRow";

class stockCleanUpTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDay: 0,
      startMonth: 0,
      startYear: 0,
      endDay: 0,
      endMonth: 0,
      endYear: 0
    };
    this.changeHandler = this.changeHandler.bind(this);
  }
  changeHandler(e) {
    if (e.target.value === "") {
      this.setState({ [e.target.name]: 0 });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }
  render() {
    let filteredOrders = this.props.orders.filter(order => {
      if (
        this.state.startYear === 0 ||
        this.state.endYear === 0 ||
        this.state.startDay === 0 ||
        this.state.endDay === 0 ||
        this.state.startMonth === 0 ||
        this.state.endMonth === 0
      ) {
        return order;
      } else {
        let orderDate = new Date(order.date.slice(0, 10));
        let startDate = new Date(
          String(
            this.state.startYear +
              "-" +
              this.state.startMonth +
              "-" +
              this.state.startDay
          )
        );
        let endDate = new Date(
          String(
            this.state.endYear +
              "-" +
              this.state.endMonth +
              "-" +
              this.state.endDay
          )
        );
        if (orderDate >= startDate && orderDate <= endDate) {
          return order;
        }
        return null;
      }
    });

    const orders = filteredOrders.map(order => (
      <UpdateOrdersRow key={order.id} order={order} />
    ));
    return (
      <div>
        <div className="row">
          <h4 className="mr-1 col-lg-1 col-sm-12">From: </h4>
          <input
            className="mx-1 form-control col-lg-1 col-sm-3 "
            type="search"
            name="startDay"
            placeholder="Start Day:"
            onChange={e => this.changeHandler(e)}
          />
          <input
            className="mx-1 form-control col-lg-1 col-sm-3 "
            type="search"
            name="startMonth"
            placeholder="Start Month:"
            onChange={e => this.changeHandler(e)}
          />
          <input
            className="mx-1 form-control col-lg-1 col-sm-3 "
            type="search"
            name="startYear"
            placeholder="Start Year:"
            onChange={e => this.changeHandler(e)}
          />
          <h4 className="col-lg-1 col-sm-12 mr-1 text-lg-right">To:</h4>
          <input
            className="mx-1 form-control col-lg-1 col-sm-3 "
            type="search"
            name="endDay"
            placeholder="End Day:"
            onChange={e => this.changeHandler(e)}
          />
          <input
            className="mx-1 form-control col-lg-1 col-sm-3 "
            type="search"
            name="endMonth"
            placeholder="End Month:"
            onChange={e => this.changeHandler(e)}
          />
          <input
            className="mx-1 form-control col-lg-1 col-sm-3 "
            type="search"
            name="endYear"
            placeholder="End Year:"
            onChange={e => this.changeHandler(e)}
          />
        </div>
        <div className="table-responsive">
          <table className="mt-3 table">
            <thead>
              <tr>
                <th className="text-success">Order Id</th>
                <th className="text-success">Order Time</th>
                <th className="text-success">Order Date</th>
                <th className="text-success">Order Status</th>
                <th className="text-success">Order Branch</th>
                <th className="text-success">Order Driver</th>
                <th className="text-success">Cancel Order</th>
              </tr>
            </thead>
            <tbody>{orders}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default stockCleanUpTable;
