import React, { Component } from "react";

class GroceryRow extends Component {
  render() {
    const grocery = this.props.grocery;
    if (grocery.id) {
      let date =
        grocery.date.slice(8, 10) +
        "-" +
        grocery.date.slice(5, 7) +
        "-" +
        grocery.date.slice(0, 4);
      let time = grocery.date.slice(11, 19);
      return (
        <tr>
          <td>{grocery.id}</td>
          <td>{grocery.name}</td>
          <td>{grocery.branch}</td>
          <td>{grocery.invoiceNo}</td>
          <td>{grocery.username}</td>
          <td>{time}</td>
          <td>{date}</td>
          <td>{grocery.total} KWD</td>
        </tr>
      );
    } else {
      return null;
    }
  }
}

export default GroceryRow;
