import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import * as actionCreators from "../../store/actions";

class NewSpending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      name: "",
      price: 0.0
    };

    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async submitHandler() {
    if (
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === 0.0
    ) {
      alert("يرجي ادخال جميع البينات");
    } else {
      this.props.createSpending(this.state, this.props.history);
    }
  }

  render() {
    return (
      <div className="card col-6 mx-auto p-0 mt-5">
        <div className="card-body">
          <h2 className="card-title float-right">تسجيل مصروفات</h2>
          <form onSubmit={this.submitHandler} noValidate>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="الاسم"
                name="name"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="الوصف"
                name="description"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="number"
                placeholder="السعر"
                name="price"
                required
                onChange={this.changeHandler}
              />
            </div>
            <h1
              className="btn btn-success float-right"
              onClick={() => this.submitHandler()}
            >
              Submit
            </h1>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  profile: state.auth.profile
});

const mapDispatchToProps = dispatch => ({
  createSpending: (spending, history) =>
    dispatch(actionCreators.createSpending(spending, history))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewSpending);
