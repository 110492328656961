import * as actionTypes from "./actionTypes";
import instance from "./instance";

export const fetchStock = () => {
  return dispatch => {
    instance
      .get("api/stock-items/")
      .then(res => res.data)
      .then(stockItems => {
        dispatch({ type: actionTypes.FETCH_STOCKS, payload: stockItems });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const createStockItem = stockitem => {
  return dispatch => {
    instance
      .post("api/stock-items/create/", stockitem)
      .then(res => res.data)
      .then(stockitem => {
        dispatch({
          type: actionTypes.ADD_STOCK_ITEM,
          payload: stockitem
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const setStockCart = profile => dispatch => {
  let cart = profile.orders.find(order => order.status === "Cart");
  if (cart) {
    dispatch({
      type: actionTypes.SET_STOCK_CART,
      payload: cart
    });
  }
};

export const createStockOrder = order => {
  return dispatch => {
    instance
      .post("api/stock-orders/create/", order)
      .then(res => res.data)
      .then(cart => {
        dispatch({
          type: actionTypes.SET_STOCK_CART,
          payload: cart
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const createStockOrderItem = (
  stockItemID,
  stockOrderID,
  quantity,
  notes
) => {
  return dispatch => {
    instance
      .post("api/stock-order-items/create/", {
        stockItem: stockItemID,
        stockOrder: stockOrderID,
        quantity: quantity,
        notes: notes
      })
      .then(res => res.data)
      .then(item => {
        dispatch({
          type: actionTypes.ADD_TO_STOCK_CART,
          payload: item
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const updateStockOrder = order => {
  return dispatch => {
    instance
      .put(`api/stock-orders/${order.id}/update/`, {
        driver: order.driver,
        status: order.status
      })
      .then(res => res.data)
      .then(ordere => {
        dispatch({
          type: actionTypes.UPDATE_STOCK_ORDER,
          payload: order
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const updateStockOrderItem = stockOrderItem => {
  return dispatch => {
    instance
      .put(`api/stock-order-items/${stockOrderItem.id}/update/`, {
        stockItem: stockOrderItem.stockItem,
        stockOrder: stockOrderItem.stockOrder,
        quantity: stockOrderItem.quantity,
        notes: stockOrderItem.notes
      })
      .then(res => res.data)
      .then(() => {
        dispatch({
          type: actionTypes.UPDATE_STOCK_ORDER_ITEM,
          payload: stockOrderItem
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const deleteStockOrderItem = (orderitem_id, history) => {
  return dispatch => {
    instance
      .delete(`api/stock-order-items/${orderitem_id}/delete/`)
      .then(() => {
        dispatch({
          type: actionTypes.DELETE_STOCKORDERITEM,
          payload: orderitem_id
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const checkoutStockCart = () => dispatch => {
  dispatch({
    type: actionTypes.CHECKOUT
  });
};

export const fetchStockOrders = () => {
  return dispatch => {
    instance
      .get("api/stock-orders/")
      .then(res => res.data)
      .then(orders => {
        dispatch({
          type: actionTypes.FETCH_STOCK_ORDERS,
          payload: orders
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const fetchStockOrdersByDay = () => {
  return dispatch => {
    instance
      .get("api/stock-orders/day/")
      .then(res => res.data)
      .then(orders => {
        dispatch({
          type: actionTypes.FETCH_STOCK_ORDERS_BY_DAY,
          payload: orders
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};
