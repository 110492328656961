import * as actionTypes from "../actions/actionTypes";

const initialState = {
  cart: {},
  cartItems: [],
  orders: [],
  ordersDay: [],
  stockItems: [],
  loading: true,
  fetchLoading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_STOCKS:
      return {
        ...state,
        stockItems: action.payload,
        fetchLoading: false
      };
    case actionTypes.ADD_STOCK_ITEM:
      return {
        ...state,
        stockItem: state.stockItem.concat(action.payload)
      };
    case actionTypes.SET_STOCK_CART:
      let orders = state.orders.concat(action.payload);
      return {
        ...state,
        cart: action.payload,
        cartItems: [],
        orders: [...orders]
      };
    case actionTypes.ADD_TO_STOCK_CART:
      state.cart.stockOrderItems = state.cart.stockOrderItems.concat(
        action.payload
      );
      let newStock = state.stockItems.find(
        item => item.id === action.payload.stockItem.id
      );
      newStock.stock = newStock.stock - action.payload.quantity;
      return {
        ...state,
        stockItems: state.stockItems,
        cartItems: state.cartItems.concat(action.payload),
        cart: state.cart
      };
    case actionTypes.UPDATE_STOCK_ORDER_ITEM:
      let newItem = state.cart.stockOrderItems.find(
        orderItem => orderItem.id === action.payload.id
      );
      newItem.quantity = action.payload.quantity;
      newItem.notes = action.payload.notes;
      let cartItem = state.cartItems.find(
        cartItem => cartItem.id === action.payload.stockItem.id
      );
      cartItem.quantity = action.payload.quantity;
      cartItem.notes = action.payload.notes;
      return {
        ...state
      };
    case actionTypes.UPDATE_STOCK_ORDER:
      let newOrder = state.orders.find(order => order.id === action.payload.id);
      // newOrder.status = action.payload.status;
      newOrder.driver = action.payload.driver;
      return {
        ...state
      };
    case actionTypes.STOCK_CHECKOUT:
      return {
        ...state,
        cartItems: []
      };
    case actionTypes.DELETE_STOCKORDERITEM:
      let list = state.cartItems.filter(
        orderitem => orderitem.id !== action.payload
      );
      return {
        ...state,
        cartItems: list
      };
    case actionTypes.FETCH_STOCK_ORDERS:
      return {
        ...state,
        orders: action.payload,
        laoding: false
      };
    case actionTypes.FETCH_STOCK_ORDERS_BY_DAY:
      return {
        ...state,
        ordersDay: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
