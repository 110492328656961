import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Actions
import * as actionCreators from "../../store/actions";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

class stockCleanUpRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.order.id,
      status: this.props.order.status,
      branch: this.props.order.branch,
      driver: this.props.order.driver
    };
    this.handleCancel = this.handleCancel.bind(this);
  }

  handlePress(order) {
    return (
      <Redirect
        to={{
          pathname: "/orderdetail",
          order: order
        }}
      />
    );
  }
  async handleCancel() {
    await this.setState({ status: "Cancelled" });
    this.props.updateOrder(this.state);
  }

  getTextColor(status) {
    switch (status) {
      case "Cart":
        return "text-dark";
      case "Ordered":
        return "text-primary";
      case "Proccessing":
        return "text-warning";
      case "Out For Delivery":
        return "text-info";
      case "Delivered":
        return "text-success";
      case "Cancelled":
        return "text-danger";
      case "Dine In":
        return "text-secondary";
      default:
      // code block
    }
  }

  render() {
    const order = this.props.order;
    if (order.id) {
      let date =
        order.date.slice(8, 10) +
        "-" +
        order.date.slice(5, 7) +
        "-" +
        order.date.slice(0, 4);
      let time = order.date.slice(11, 19);
      let cancelCol = (
        <button
          className="btn btn-outline-danger"
          aria-label="Checkbox for following text input"
          onClick={() => this.handleCancel()}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      );
      if (this.state.status === "Cancelled") {
        cancelCol = <h4>-</h4>;
      }
      return (
        <tr>
          <td>
            <Link className="text-dark" to={`/stockorders/${order.id}`}>
              {order.id}
            </Link>
          </td>
          <td>
            <Link className="text-dark" to={`/stockorders/${order.id}`}>
              {time}
            </Link>
          </td>
          <td>
            <Link className="text-dark" to={`/stockorders/${order.id}`}>
              {date}
            </Link>
          </td>
          <td>
            <Link
              className={this.getTextColor(this.state.status)}
              to={`/stockorders/${order.id}`}
            >
              {this.state.status}
            </Link>
          </td>
          <td>
            <Link className="text-dark" to={`/stockorders/${order.id}`}>
              {order.branch}
            </Link>
          </td>
          <td>
            <Link className="text-dark" to={`/stockorders/${order.id}`}>
              {order.driver}
            </Link>
          </td>
          <td>{cancelCol}</td>
        </tr>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  customers: state.cust.customers
});

const mapDispatchToProps = dispatch => ({
  updateOrder: order => dispatch(actionCreators.updateStockOrder(order))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(stockCleanUpRow);
