import React, { Component } from "react";
import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";
import StockItemRow from "./StockItemRow";

class AvailableStockItems extends Component {
  componentDidMount() {
    this.props.fetchStock();
  }
  render() {
    if (this.props.loading) {
      return <h2>Loading...</h2>;
    } else {
      let total = 0;
      let totalhidden = 0;
      let stockItems = this.props.stockItems.map(item => {
        if (item.available) {
          total += item.stock * item.price;
          return <StockItemRow stockItem={item} key={item.id} />;
        } else {
          totalhidden += item.stock * item.price;
          console.log("Hidden", totalhidden);
          return null;
        }
      });
      return (
        <div className="card col-11 p-0 mx-auto ">
          <h2 className="card-header text-center">متابعة الستوكات</h2>
          <div className="card-body">
            <div className="table-responsive">
              <table className="mt-3 table">
                <thead>
                  <tr>
                    <th className="text-success">Item Name</th>
                    <th className="text-success">Category</th>
                    <th className="text-success">Stock</th>
                    <th className="text-success">Price</th>
                  </tr>
                </thead>
                <tbody>{stockItems}</tbody>
              </table>
            </div>
            <h2>Total Available Stock Price: {total.toFixed(3)} KD</h2>
            <h2>Total Hidden Stock Price: {totalhidden.toFixed(3)} KD</h2>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  stockItems: state.stockCart.stockItems
});
const mapDispatchToProps = dispatch => ({
  fetchStock: () => dispatch(actionCreators.fetchStock())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableStockItems);
