import React, { Component } from "react";
// Components

import { connect } from "react-redux";

class DailyClose extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.loading) {
      //Confirmed
      let talabatKnet = 0.0;
      let talabatCash = 0.0;
      let deliveroKnet = 0.0;
      let deliveroCash = 0.0;
      let carriageKnet = 0.0;
      let carriageCash = 0.0;
      let cravezKnet = 0.0;
      let cravezCash = 0.0;
      let callKnet = 0.0;
      let callCash = 0.0;
      let webCash = 0.0;
      let webKnet = 0.0;
      let restKnet = 0.0;
      let restCash = 0.0;
      let waitKnet = 0.0;
      let waitCash = 0.0;
      let totalCash = 0.0;
      let totalKnet = 0.0;
      let totalIncome = 0.0;
      let spending = 0.0;
      let grocery = 0.0;
      let stock = 0.0;

      //Cancelled
      let ctalabatKnet = 0.0;
      let ctalabatCash = 0.0;
      let cdeliveroKnet = 0.0;
      let cdeliveroCash = 0.0;
      let ccarriageKnet = 0.0;
      let ccarriageCash = 0.0;
      let ccravezKnet = 0.0;
      let ccravezCash = 0.0;
      let ccallKnet = 0.0;
      let ccallCash = 0.0;
      let cwebCash = 0.0;
      let cwebKnet = 0.0;
      let crestKnet = 0.0;
      let crestCash = 0.0;
      let cwaitKnet = 0.0;
      let cwaitCash = 0.0;
      let ctotalCash = 0.0;
      let ctotalKnet = 0.0;
      let ctotalIncome = 0.0;

      if (this.props.orders.cash) {
        this.props.orders.cash.forEach((detail) => {
          switch (detail.source) {
            case "Restaurant":
              restCash = detail.total;
              break;
            case "Talabat":
              talabatCash = detail.total;
              break;
            case "Delivero":
              deliveroCash = detail.total;
              break;
            case "Carriage":
              carriageCash = detail.total;
              break;
            case "Cravez":
              cravezCash = detail.total;
              break;
            case "Call":
              callCash = detail.total;
              break;
            case "Waiter":
              waitCash = detail.total;
              break;
            case "Website":
              webCash = detail.total;
              break;
            default:
              break;
          }
          totalCash += detail.total;
          totalIncome += detail.total;
        });
      }

      if (this.props.orders.cash) {
        this.props.ordersCancelled.cash.forEach((detail) => {
          switch (detail.source) {
            case "Restaurant":
              crestCash = detail.total;
              break;
            case "Talabat":
              if (detail.total === null) ctalabatCash = 0;
              else ctalabatCash = detail.total;
              break;
            case "Delivero":
              cdeliveroCash = detail.total;
              break;
            case "Carriage":
              ccarriageCash = detail.total;
              break;
            case "Cravez":
              ccravezCash = detail.total;
              break;
            case "Call":
              if (detail.total === null) ccallCash = 0;
              else ccallCash = detail.total;
              break;
            case "Waiter":
              cwaitCash = detail.total;
              break;
            case "Website":
              cwebCash = detail.total;
              break;
            default:
              break;
          }
          ctotalCash += detail.total;
          ctotalIncome += detail.total;
        });
      }

      if (this.props.orders.knet) {
        this.props.orders.knet.forEach((detail) => {
          switch (detail.source) {
            case "Restaurant":
              restKnet = detail.total;
              break;
            case "Talabat":
              talabatKnet = detail.total;
              break;
            case "Delivero":
              deliveroKnet = detail.total;
              break;
            case "Carriage":
              carriageKnet = detail.total;
              break;
            case "Cravez":
              cravezKnet = detail.total;
              break;
            case "Call":
              callKnet = detail.total;
              break;
            case "Waiter":
              waitKnet = detail.total;
              break;
            case "Website":
              webKnet = detail.total;
              break;
            default:
              break;
          }
          totalKnet += detail.total;
          totalIncome += detail.total;
        });
      }

      if (this.props.ordersCancelled.knet) {
        this.props.ordersCancelled.knet.forEach((detail) => {
          switch (detail.source) {
            case "Restaurant":
              crestKnet = detail.total;
              break;
            case "Talabat":
              if (detail.total === null) ctalabatKnet = 0.0;
              else ctalabatKnet = detail.total;
              break;
            case "Delivero":
              cdeliveroKnet = detail.total;
              break;
            case "Carriage":
              ccarriageKnet = detail.total;
              break;
            case "Cravez":
              ccravezKnet = detail.total;
              break;
            case "Call":
              ccallKnet = detail.total;
              break;
            case "Waiter":
              cwaitKnet = detail.total;
              break;
            case "Website":
              cwebKnet = detail.total;
              break;
            default:
              break;
          }
          ctotalKnet += detail.total;
          ctotalIncome += detail.total;
        });
      }

      if (this.props.spendings.total) {
        spending = this.props.spendings.total;
      }

      if (this.props.stockOrders.total) {
        stock = this.props.stockOrders.total;
      }

      if (this.props.groceries.total) {
        grocery = this.props.groceries.total;
      }

      let range = this.props.location.state.range;
      let branch = this.props.location.state.branch;
      return (
        <div className="card col-11 m-auto">
          <div className="card-body">
            <h1 className="card-title float-right">كشف الايرادات</h1>
            <div className="row mt-5">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 text-right">
                <h6>{branch}:الفرع</h6>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 text-right">
                <h6>
                  الوقت من: {range.start_hour} الى: {range.end_hour}
                </h6>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 text-right">
                <h6>
                  التاريخ من: {range.start_date} الى: {range.end_date}
                </h6>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="table-success">
                    <th scope="col" className="text-center">
                      Cash(KWD)
                    </th>
                    <th scope="col" className="text-center">
                      K-net(KWD)
                    </th>
                    <th scope="col" className="text-right">
                      الفرع
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{callCash.toFixed(3)}</td>
                    <td className="text-center">{callKnet.toFixed(3)}</td>
                    <th className="text-right">كول سنتر</th>
                  </tr>
                  <tr>
                    <td className="text-center">{talabatCash.toFixed(3)}</td>
                    <td className="text-center">{talabatKnet.toFixed(3)}</td>
                    <th className="text-right">طلبات</th>
                  </tr>
                  <tr>
                    <td className="text-center">{deliveroCash.toFixed(3)}</td>
                    <td className="text-center">{deliveroKnet.toFixed(3)}</td>
                    <th className="text-right">دليفرو</th>
                  </tr>
                  <tr>
                    <td className="text-center">{carriageCash.toFixed(3)}</td>
                    <td className="text-center">{carriageKnet.toFixed(3)}</td>
                    <th className="text-right">كاردج</th>
                  </tr>
                  <tr>
                    <td className="text-center">{cravezCash.toFixed(3)}</td>
                    <td className="text-center">{cravezKnet.toFixed(3)}</td>
                    <th className="text-right">كريفز</th>
                  </tr>
                  <tr>
                    <td className="text-center">{waitCash.toFixed(3)}</td>
                    <td className="text-center">{waitKnet.toFixed(3)}</td>
                    <th className="text-right">جرسون</th>
                  </tr>
                  <tr>
                    <td className="text-center">{webCash.toFixed(3)}</td>
                    <td className="text-center">{webKnet.toFixed(3)}</td>
                    <th className="text-right">الموقع</th>
                  </tr>
                  <tr>
                    <td className="text-center">{restCash.toFixed(3)}</td>
                    <td className="text-center">{restKnet.toFixed(3)}</td>
                    <th className="text-right">الكاشير</th>
                  </tr>
                  <tr className="table-dark">
                    <td className="text-center">{totalCash.toFixed(3)}</td>
                    <td className="text-center">{totalKnet.toFixed(3)}</td>
                    <th className="text-right">المجموع</th>
                  </tr>
                  <tr className="table-success">
                    <td className="text-center">
                      {" "}
                      KWD {totalIncome.toFixed(3)}
                    </td>
                    <th className="text-right">:مجموع الإيرادات </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <h1 className="card-title float-right">الطلبات الملغيه</h1>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="table-success">
                    <th scope="col" className="text-center">
                      Cash(KWD)
                    </th>
                    <th scope="col" className="text-center">
                      K-net(KWD)
                    </th>
                    <th scope="col" className="text-right">
                      الفرع
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{ccallCash.toFixed(3)}</td>
                    <td className="text-center">{ccallKnet.toFixed(3)}</td>
                    <th className="text-right">كول سنتر</th>
                  </tr>
                  <tr>
                    <td className="text-center">{ctalabatCash.toFixed(3)}</td>
                    <td className="text-center">{ctalabatKnet.toFixed(3)}</td>
                    <th className="text-right">طلبات</th>
                  </tr>
                  <tr>
                    <td className="text-center">{cdeliveroCash.toFixed(3)}</td>
                    <td className="text-center">{cdeliveroKnet.toFixed(3)}</td>
                    <th className="text-right">دليفرو</th>
                  </tr>
                  <tr>
                    <td className="text-center">{ccarriageCash.toFixed(3)}</td>
                    <td className="text-center">{ccarriageKnet.toFixed(3)}</td>
                    <th className="text-right">كاردج</th>
                  </tr>
                  <tr>
                    <td className="text-center">{ccravezCash.toFixed(3)}</td>
                    <td className="text-center">{ccravezKnet.toFixed(3)}</td>
                    <th className="text-right">كريفز</th>
                  </tr>
                  <tr>
                    <td className="text-center">{cwaitCash.toFixed(3)}</td>
                    <td className="text-center">{cwaitKnet.toFixed(3)}</td>
                    <th className="text-right">جرسون</th>
                  </tr>
                  <tr>
                    <td className="text-center">{cwebCash.toFixed(3)}</td>
                    <td className="text-center">{cwebKnet.toFixed(3)}</td>
                    <th className="text-right">الموقع</th>
                  </tr>
                  <tr>
                    <td className="text-center">{crestCash.toFixed(3)}</td>
                    <td className="text-center">{crestKnet.toFixed(3)}</td>
                    <th className="text-right">الكاشير</th>
                  </tr>
                  <tr className="table-dark">
                    <td className="text-center">{ctotalCash.toFixed(3)}</td>
                    <td className="text-center">{ctotalKnet.toFixed(3)}</td>
                    <th className="text-right">المجموع</th>
                  </tr>
                  <tr className="table-success">
                    <td className="text-center">
                      {" "}
                      KWD {ctotalIncome.toFixed(3)}
                    </td>
                    <th className="text-right">:مجموع الطلبات الملغيه </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <h1 className="card-title float-right">كشف طلبات المخزن</h1>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="table-success">
                    <th scope="col" className="text-center">
                      صرف من المخزن
                    </th>
                    <th scope="col" className="text-right">
                      الفرع
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{stock.toFixed(3)}</td>
                    <th className="text-right"> طلبات الفرع المحدد</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <h1 className="card-title float-right">كشف مصروفات الماجلة</h1>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="table-success">
                    <th scope="col" className="text-center">
                      ماجله الافرع
                    </th>
                    <th scope="col" className="text-right">
                      الفرع
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{grocery.toFixed(3)}</td>
                    <th className="text-right">ماجلة الفرع المحدد</th>
                  </tr>
                </tbody>
              </table>
            </div>

            <h1 className="card-title float-right">كشف المصروفات</h1>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="table-success">
                    <th scope="col" className="text-center">
                      مصروفات الافرع
                    </th>
                    <th scope="col" className="text-right">
                      الفرع
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{spending.toFixed(3)}</td>
                    <th className="text-right">مصروفات الفرع المحدد</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  loading: state.report.loadingD,
  orders: state.report.ordersD,
  ordersCancelled: state.report.ordersCancelled,
  stockOrders: state.report.stockOrdersD,
  groceries: state.report.groceriesD,
  spendings: state.report.spendingsD,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DailyClose);
