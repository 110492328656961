import React, { Component } from "react";

import { connect } from "react-redux";

// Actions
import * as actionCreators from "../store/actions";

class NewCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      phoneNo: null,
      phoneNo2: null,
      area: "اختر المنطقة",
      block: null,
      street: null,
      jaddah: null,
      house_building: null,
      floor: null,
      appartment: null,
      extra_directions: null
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentDidMount() {
    let phone = this.props.location.state.phone;
    if (phone) {
      this.setState({ phoneNo: phone });
    }
  }

  submitHandler(e) {
    if (
      this.state.name !== null &&
      this.state.phoneNo !== null &&
      this.state.area !== "اختر المنطقة" &&
      this.state.block !== null &&
      this.state.street !== null &&
      this.state.house_building !== null
    ) {
      e.preventDefault();
      this.props.createCustomer(this.state, this.props.history);
    } else {
      alert("يرجى ادخال البينات الاجبارية");
    }
  }

  render() {
    let areas = [
      "اختر المنطقة",
      "ابو فطيرة",
      "ابو الحصانية",
      "العدان",
      "المسايل",
      "القرين",
      "القصور",
      "فنيطيس",
      "المسيلة",
      "مبارك الكبير",
      "صباح السالم",
      "صبحان الصناعية",
      "ابو حليفة",
      "الاحمدي",
      "علي صباح السالم - ام الهيمان",
      "الظهر",
      "العقيلة",
      "فهد الاحمدي",
      "الفنطاس",
      "الفحيحيل",
      "هدية",
      "جابر العلي",
      "المهبولة",
      "المنقف",
      "ميناء عبدالله",
      "الرقة",
      "الصباحية",
      "جنوب الصباحية",
      "بيان",
      "حطين",
      "الجابرية",
      "منطقة الوزارات",
      "مشرف",
      "مبارك العبدالله غرب مشرف",
      "الرميثيه",
      "السلام",
      "سلوى",
      "الشهداء",
      "الصديق",
      "الزهراء",
      "--------------",
      "عبدالله المبارك",
      "المطار",
      "الاندلس",
      "العارضية٤",
      "العارضية",
      "عارضية الصناعية",
      "عارضية مخازن",
      "اشبيليا",
      "ضجيج",
      "الفروانية",
      "الفردوس",
      "جليب الشيوخ",
      "خيطان",
      "العمرية",
      "الرابية",
      "الري",
      "الرقعي",
      "الرحاب",
      "صباح الناصر",
      "مطار الشيخ سعد العبدالله",
      "عبدالله السالم",
      "العديلية",
      "البارحة",
      "بنيد القار",
      "الدعية",
      "الدسمة",
      "دسمان",
      "الدوحة",
      "الفيحاء",
      "غرناطة",
      "جابر الاحمد",
      "كيفان",
      "الخالدية",
      "مدينة الكويت",
      "المنطقة الحرة",
      "المنصورية",
      "المرقاب",
      "معسكرات المباركية",
      "النهضة",
      "شمال غرب الصليبخات",
      "النزهة",
      "القادسية",
      "القبلة",
      "قرطبة",
      "الروضة",
      "الصالحية",
      "الشامية",
      "الشرق",
      "شويخ الادارية",
      "شويخ التعليمية",
      "شويخ الصناعية",
      "شويخ الصحية",
      "شويخ السكنية",
      "الشيويخ",
      "الصليبخات",
      "السرة",
      "اليرموك",
      "البدع",
      "حولي",
      "ميدان حولي",
      "السالمية",
      "الشعب",
      "الجليعة",
      "بنيدر",
      "الخيران",
      "مقوع",
      "النويصيب",
      "مدينة صباح الاحمد البحرية",
      "مدينة صباح الاحمد السكنية",
      "الضباعية",
      "ميناء الشعيبة",
      "مزارع الوفرة",
      "الوفرة السكنية",
      "الزور",
      "النعيم",
      "امغرة الصناعية",
      "منطقة الجهراء",
      "كبد",
      "النسيم",
      "العيون",
      "قصر",
      "سعد العبدالله",
      "صليبيةالصناعية",
      "صليبية السكنية",
      "صليبية",
      "تيماء",
      "الواحة",
      "القيروان",
      "جنوب الوسطى",
      "غرب ابوفطيرة الحرفية",
      "وسطى"
    ];
    let areaslist = areas.map(area => (
      <option key={area} className="" value={area}>
        {area}
      </option>
    ));
    return (
      <div className="card col-lg-6 col-md-10 col-sm-11 mx-auto p-0 mt-5 text-right">
        <div className="card-body text-right">
          <h2 className="card-title pb-1">عميل جديد</h2>
          <form onSubmit={this.submitHandler} noValidate>
            <div className="form-group">
              <input
                className="form-control text-right"
                type="text"
                placeholder="اسم العميل -- اجباري"
                name="name"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control text-right"
                type="text"
                placeholder="رقم العميل الاساسي -- اجباري"
                value={this.state.phoneNo}
                name="phoneNo"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control text-right"
                type="text"
                placeholder="رقم العميل الثانوي -- اختياري"
                name="phoneNo2"
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group ">
              <select
                name="area"
                value={this.state.area}
                className="form-control text-right"
                onChange={this.changeHandler}
              >
                {areaslist}
              </select>
            </div>
            <div className="form-group">
              <input
                className="form-control text-right"
                type="text"
                placeholder="القطعة -- اجباري"
                name="block"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control text-right"
                type="text"
                placeholder="الشارع -- اجباري"
                name="street"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control text-right"
                type="text"
                placeholder="الجادة -- اختياري"
                name="jaddah"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control text-right"
                type="text"
                placeholder="المنزل او البناية -- اجباري"
                name="house_building"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control text-right"
                type="text"
                placeholder="الدور -- اختياري)"
                name="floor"
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control text-right"
                type="text"
                placeholder="الشقة/ المكتب -- اختياري"
                name="appartment"
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control text-right"
                type="text"
                placeholder="وصف اضافي -- اختياري"
                name="extra_directions"
                onChange={this.changeHandler}
              />
            </div>
            <input className="btn btn-success col-12" type="submit" />
          </form>
        </div>
        <div className="card-footer" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  customers: state.cust.customers
});

const mapDispatchToProps = dispatch => ({
  createCustomer: (customer, history) =>
    dispatch(actionCreators.createCustomer(customer, history))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCustomer);
