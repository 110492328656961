import React, { Component } from "react";

// Components
import AuthButton from "./AuthButton";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class NavBar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-success  sticky-top mb-5">
        <Link
          className="navbar-brand"
          to="/home"
          style={{
            fontFamily: "Times New Roman, Times, serif",
            color: "white",
            fontWeight: "bold",
          }}
        >
          Simply Juice
        </Link>
        <small
          className="navbar-brand"
          style={{
            fontFamily: "Times New Roman, Times, serif",
            color: "white",
            fontSize: "8pt",
          }}
        >
          V3.3
        </small>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            {this.props.cart ? (
              <li className="nav-item ">
                <Link className="nav-link" to="/list">
                  Products <span className="sr-only">(current)</span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            <li>
              <AuthButton />
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    cart: state.cart.cart,
  };
};
export default connect(mapStateToProps, null)(NavBar);
