import React, { Component } from "react";
import DatePicker from "react-datepicker";

import dateMethods from "../../store/actions/dateMethods";
import "react-datepicker/dist/react-datepicker.css";

import SpendRow from "./SpendRow";

class SpendTable extends Component {
  state = {
    allStockOrders: true,
    startDate: new Date(),
    endDate: new Date()
  };

  handleChangeStart = date => {
    this.setState({
      startDate: date,
      allStockOrders: false
    });
  };

  handleChangeEnd = date => {
    this.setState({
      endDate: date,
      allStockOrders: false
    });
  };

  clearDateFilter = () => {
    this.setState({
      allStockOrders: true
    });
  };
  render() {
    let filteredSpendings = this.props.spendings;

    if (!this.state.allStockOrders) {
      filteredSpendings = filteredSpendings.filter(order => {
        let orderDate = new Date(order.date.slice(0, 10));
        if (
          dateMethods.inRange(
            orderDate,
            this.state.startDate,
            this.state.endDate
          )
        ) {
          return order;
        }
        return null;
      });
    }

    const spendings = filteredSpendings.map(spending => (
      <SpendRow key={spending.id} spending={spending} />
    ));
    return (
      <div>
        <div className="row">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">Filter By Date</span>
            </div>
            <DatePicker
              className="form-control"
              selected={this.state.startDate}
              onChange={this.handleChangeStart}
              selectsStart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            <div className="input-group-prepend">
              <span className="input-group-text">To</span>
            </div>

            <DatePicker
              className="form-control"
              selected={this.state.endDate}
              onChange={this.handleChangeEnd}
              selectsEnd
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            <div className="input-group-prepend" onClick={this.clearDateFilter}>
              <button className="input-group-text btn btn-warning">
                Clear Dates
              </button>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="mt-3 table">
            <thead>
              <tr>
                <th className="text-success">Spending Id</th>
                <th className="text-success">Spending Name</th>
                <th className="text-success">Spending Description</th>
                <th className="text-success">Spending Branch</th>
                <th className="text-success">Spending Username</th>
                <th className="text-success">Spending Time</th>
                <th className="text-success">Spending Date</th>
                <th className="text-success">Spending Total</th>
              </tr>
            </thead>
            <tbody>{spendings}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default SpendTable;
