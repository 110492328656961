import React, { Component } from "react";
import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

class OrderItemRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const orderItem = this.props.orderItem;

    const varient = this.props.varients.find(
      varient => varient.id === orderItem.itemVarient.id
    );
    let addOns = orderItem.addOns.map(addOn => addOn.name + ", ");
    const item = this.props.items.find(item => item.id === varient.item);
    if (orderItem && varient && item) {
      return (
        <React.Fragment>
          <tr className="tableMid">
            <td>
              <p className="border-0 sm-font-size p-0 mb-0">{item.name}</p>
              <p className="sm-font-size p-0 mb-0">{varient.varient}</p>
              <p className="sm-font-size p-0 mb-0">{addOns}</p>
            </td>
            <td className="sm-font-size">{orderItem.quantity}</td>
            <td className="sm-font-size">{orderItem.total.toFixed(3)}</td>
            <td className="sm-font-size">
              {(orderItem.total * orderItem.quantity).toFixed(3)}
            </td>
          </tr>

          {orderItem.notes ? (
            <tr className="sm-font-size">
              <td colspan="4">notes: {orderItem.notes}</td>
            </tr>
          ) : (
            ""
          )}
        </React.Fragment>
      );
    } else {
      return (
        <tr className="tableMid text-center">
          <p className="sm-font-size">Loading</p>
        </tr>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    varients: state.items.varients,
    items: state.items.items
  };
};
const mapActionToState = dispatch => {
  return {
    deleteOrderItem: orderitem_id =>
      dispatch(actionCreators.deleteOrderItem(orderitem_id)),
    updateOrderItem: (orderItem, history) =>
      dispatch(actionCreators.updateOrderItem(orderItem, history))
  };
};
export default connect(
  mapStateToProps,
  mapActionToState
)(OrderItemRow);
