import React, { Component } from "react";

// Components
import UpdateOrdersTable from "./WaiterOrderTable";

import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

class waiterOrder extends Component {
  componentDidMount() {}
  render() {
    if (this.props.orders && this.props.profile) {
      let branchOrders = this.props.orders.filter(
        order =>
          order.source === "Waiter" &&
          order.branch === this.props.profile.branch
      );

      return (
        <div className="card mx-auto col-11">
          <div className="card-body">
            <h1 className="text-center "> طلبات الجراسين </h1>
            {branchOrders.length !== 0 ? (
              <UpdateOrdersTable orders={branchOrders} />
            ) : (
              <p className="text-center text-danger"> No Orders </p>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  orderItems: state.cart.cartItems,
  cart: state.cart.cart,
  customers: state.cust.customers,
  orders: state.cart.ordersDay,
  profile: state.auth.profile
});
const mapDispatchToProps = dispatch => ({
  fetchProfile: () => dispatch(actionCreators.fetchProfile())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(waiterOrder);
