// Actions
import * as actionCreators from "../../store/actions";

import React, { Component } from "react";

import { connect } from "react-redux";

class EditOrder extends Component {
  state = {
    id: this.props.location.order.id,
    status: this.props.location.order.status,
    source: this.props.location.order.source,
    payment: this.props.location.order.payment,
    branch: this.props.location.order.branch,
    driver: this.props.location.order.driver,
    customer: this.props.location.order.customer.id,
    discount: this.props.location.order.discount,
    deliveryCharges: this.props.location.order.deliveryCharges,
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = async (event) => {
    await this.props.updateOrder(this.state, this.props.history);
    this.props.history.push("/home");
  };

  setDelivery(charges) {
    this.setState({ deliveryCharges: charges.target.value });
  }
  setDiscount(charges) {
    this.setState({ discount: charges.target.value });
  }

  render() {
    let drivers = [
      "None",
      "Qassim",
      "Faheem",
      "Jifan",
      "Shasika",
      "Erfan",
      "Haider",
      "Bandar",
      "Arsan",
      "Fawzar",
      "Saddeeq",
      "Refas",
      "Rawan",
      "Rafenda",
      "Rakshan",
      "AliHassan",
      "MohammedOmair",
    ];
    let driversList = drivers.map((driver) => {
      return (
        <option key={driver} value={driver}>
          {driver}
        </option>
      );
    });
    return (
      <div className="card col-6 mx-auto p-0 mt-5">
        <div className="card-body">
          <div className="row">
            <form className="input-group mb-3" onSubmit={this.handleSubmit}>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Status</span>
                </div>
                <select
                  name="status"
                  value={this.state.status}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option value="Cart">Cart</option>
                  <option value="Ordered">Ordered</option>
                  <option value="Proccessing">Proccessing</option>
                  <option value="Out For Delivery">Out For Delivery</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Dine In">Dine In</option>
                </select>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Source</span>
                </div>
                <select
                  name="source"
                  value={this.state.source}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option value="Call">Call</option>
                  <option value="Website">Website</option>
                  <option value="Talabat">Talabat</option>
                  <option value="Delivero">Delivero</option>
                  <option value="Carriage">Carriage</option>
                  <option value="Cravez">Cravez</option>
                  <option value="Restaurant">Restaurant</option>
                </select>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Payment</span>
                </div>
                <select
                  name="payment"
                  value={this.state.payment}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option value="Cash">Cash</option>
                  <option value="Knet">Knet</option>
                </select>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Branch</span>
                </div>
                <select
                  name="branch"
                  value={this.state.branch}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option value="Mangaf">Mangaf</option>
                  <option value="Fintas 1">Fintas 1</option>
                  <option value="Fintas 2">Fintas 2</option>
                  <option value="Sulaibiya">Sulaibiya</option>
                </select>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Driver</span>
                </div>
                <select
                  name="driver"
                  value={this.state.driver}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  {driversList}
                </select>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Discount</span>
                </div>
                <input
                  className="form-control "
                  type="number"
                  name="discount"
                  value={this.state.discount}
                  onChange={(e) => this.setDiscount(e)}
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Delivery Charges</span>
                </div>
                <input
                  className="form-control "
                  type="number"
                  name="deliveryCharges"
                  value={this.state.deliveryCharges}
                  onChange={(e) => this.setDelivery(e)}
                />
              </div>
              <button
                className="btn btn-primary"
                onClick={() => this.handleSubmit()}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateOrder: (order, history) =>
    dispatch(actionCreators.updateOrder(order, history)),
});

export default connect(null, mapDispatchToProps)(EditOrder);
