import * as actionTypes from "../actions/actionTypes";

const initialState = {
  groceries: [],
  spendings: [],
  invoices: [],
  expenses: []
};
const spendReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SPENDINGS:
      return {
        ...state,
        spendings: action.payload
      };
    case actionTypes.FETCH_EXPENSES:
      return {
        ...state,
        expenses: action.payload
      };
    case actionTypes.FETCH_GROCERIES:
      return {
        ...state,
        groceries: action.payload
      };
    case actionTypes.FETCH_INVOICES:
      return {
        ...state,
        invoices: action.payload
      };
    case actionTypes.ADD_SPENDING:
      return {
        ...state,
        spendings: state.spendings.concat(action.payload)
      };
    case actionTypes.ADD_GROCERY:
      return {
        ...state,
        groceries: state.groceries.concat(action.payload)
      };
    case actionTypes.ADD_INVOICE:
      return {
        ...state,
        invoices: state.invoices.concat(action.payload)
      };

    default:
      return state;
  }
};

export default spendReducer;
