import * as actionTypes from "./actionTypes";
import instance from "./instance";

export const fetchItems = () => {
  return dispatch => {
    instance
      .get("api/items/")
      .then(res => res.data)
      .then(items => {
        dispatch({ type: actionTypes.FETCH_ITEMS, payload: items });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const fetchVarients = () => {
  return dispatch => {
    instance
      .get("api/varients/")
      .then(res => res.data)
      .then(varients => {
        dispatch({ type: actionTypes.FETCH_VARIENTS, payload: varients });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};
