import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
class StockOrdersRow extends Component {
  getTextColor(status) {
    switch (status) {
      case "Cart":
        return "text-dark";

      case "Ordered":
        return "text-primary";

      case "Proccessing":
        return "text-warning";
      case "Out For Delivery":
        return "text-info";
      case "Delivered":
        return "text-success";
      case "Cancelled":
        return "text-danger";
      case "Dine In":
        return "text-secondary";
      default:
      // code block
    }
  }

  render() {
    const { order } = this.props;
    let date =
      order.date.slice(8, 10) +
      "-" +
      order.date.slice(5, 7) +
      "-" +
      order.date.slice(0, 4);
    let time = order.date.slice(11, 19);
    return (
      <tr>
        <td>
          <Link className="text-dark" to={`/stockorders/${order.id}`}>
            {order.id}
          </Link>
        </td>
        <td>
          <Link className="text-dark" to={`/stockorders/${order.id}`}>
            {time}
          </Link>
        </td>
        <td>
          <Link className="text-dark" to={`/stockorders/${order.id}`}>
            {date}
          </Link>
        </td>
        <td>
          <Link
            className={this.getTextColor(order.status)}
            to={`/stockorders/${order.id}`}
          >
            {order.status}
          </Link>
        </td>
        <td>
          <Link className="text-dark" to={`/stockorders/${order.id}`}>
            {order.branch}
          </Link>
        </td>
        <td>
          <Link className="text-dark" to={`/stockorders/${order.id}`}>
            {order.driver}
          </Link>
        </td>
        {this.props.profile.position !== "Cashier" ? (
          <td>
            <Link className="text-dark" to={`/stockorders/${order.id}`}>
              {order.total}
            </Link>
          </td>
        ) : (
          <></>
        )}
      </tr>
    );
  }
}
const mapStateToProps = state => ({
  profile: state.auth.profile
});

export default connect(mapStateToProps)(StockOrdersRow);
