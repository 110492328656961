import React, { Component } from "react";
import { connect } from "react-redux";

import StockRow from "./StockRow";

class StockTable extends Component {
  render() {
    const { cartItems } = this.props;
    const cartRow = cartItems.map(cartItem => {
      return <StockRow key={cartItem.id} cartItem={cartItem} />;
    });
    return (
      <div className="container">
        <div className="row float-right ">
          <h3 className="p "> </h3>
        </div>
        <table className="mt-3 table">
          <thead>
            <tr>
              <th>Item Image</th>
              <th>Item Name</th>
              <th>Category</th>
              <th>Quantity</th>
              <th>Notes</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{cartRow}</tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cartItems: state.stockCart.cartItems,
    stockItems: state.stockCart.stockItems
  };
};

export default connect(mapStateToProps)(StockTable);
