import React, { Component } from "react";
import { connect } from "react-redux";
import Countdown from "react-countdown-now";
import * as actionCreators from "../../store/actions";
import Sound from "react-sound";
import StockOrdersTable from "./StockOrdersTable";
import audioAlert from "../../alert/alert_tones.mp3";

class StockOrdersToday extends Component {
  state = {
    play: this.props.play,
    key: 0
  };
  componentDidMount() {
    this.props.fetchStockOrdersByDay();
    this.timer = setInterval(() => {
      this.setState({ key: this.state.key + 1 });
      this.props.fetchStockOrdersByDay();
    }, 300000);
  }
  componentDidUpdate(prevState) {
    let prevOrders = prevState.stockOrders.filter(
      order => order.status === "Ordered" || order.status === "Proccessing"
    );
    let newOrders = this.props.stockOrders.filter(
      order => order.status === "Ordered" || order.status === "Proccessing"
    );
    if (prevOrders.length === 0 && newOrders.length > 0) {
      this.props.soundplay(Sound.status.PLAYING);
    } else if (prevOrders.length > 0 && newOrders.length > 0) {
      if (
        !prevOrders.find(
          order => order.id === newOrders[newOrders.length - 1].id
        )
      ) {
        this.props.soundplay(Sound.status.PLAYING);
      }
    }
  }

  render() {
    if (this.props.loading || !this.props.profile) {
      return <h2>Loading...</h2>;
    } else {
      let stockOrders = this.props.stockOrders.filter(
        order => order.status === "Ordered" || order.status === "Proccessing"
      );
      return (
        <div className="card col-11 p-0 mx-auto">
          <Sound url={audioAlert} playStatus={this.props.play} loop={true} />
          <div className="card-body">
            <div className="row">
              <div className="col-12 text-center">
                <Countdown key={this.state.key} date={Date.now() + 300000} />
              </div>
            </div>
            <h1 className="text-center card-title"> طلبات الستوكات </h1>
            <StockOrdersTable stockOrders={stockOrders} />
          </div>
          <button
            className="btn btn-danger"
            onClick={() => this.props.soundplay(Sound.status.STOPPED)}
          >
            الغاء الصوت
          </button>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  stockOrders: state.stockCart.ordersDay,
  play: state.cart.play,
  profile: state.auth.profile
});
const mapDispatchToProps = dispatch => ({
  fetchStockOrdersByDay: () => dispatch(actionCreators.fetchStockOrdersByDay()),
  soundplay: sound => dispatch(actionCreators.soundplay(sound))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockOrdersToday);
