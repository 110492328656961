import React, { Component } from "react";
import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";
import StockOrdersTable from "./StockOrdersTable";

class StockOrders extends Component {
  componentDidMount() {}
  render() {
    if (this.props.loading) {
      return <h2>Loading...</h2>;
    } else {
      let areas = ["Mangaf", "Fintas 1", "Fintas 2", "Sulaibiya"];
      let areaTables = areas.map((area, idx) => {
        let orders = this.props.stockOrders.filter(
          order => order.branch === area
        );
        return <StockOrdersTable stockOrders={orders} area={area} key={idx} />;
      });
      return (
        <div className="card col-11 m-auto p-0">
          <h1 className="card-header bg-light text-dark text-center">
            {" "}
            طلبات الستوكات{" "}
          </h1>
          {areaTables}
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  stockOrders: state.stockCart.orders
});
const mapDispatchToProps = dispatch => ({
  fetchStockOrders: () => dispatch(actionCreators.fetchStockOrders())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockOrders);
