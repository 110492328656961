import React, { Component } from "react";
import DatePicker from "react-datepicker";

import dateMethods from "../../store/actions/dateMethods";
import "react-datepicker/dist/react-datepicker.css";

import UpdateOrdersRow from "./WaiterOrderRows";

class WaiterOrderTable extends Component {
  state = {
    allStockOrders: true,
    startDate: new Date(),
    endDate: new Date()
  };

  handleChangeStart = date => {
    this.setState({
      startDate: date,
      allStockOrders: false
    });
  };

  handleChangeEnd = date => {
    this.setState({
      endDate: date,
      allStockOrders: false
    });
  };

  clearDateFilter = () => {
    this.setState({
      allStockOrders: true
    });
  };

  render() {
    let filteredOrders = this.props.orders;

    if (!this.state.allStockOrders) {
      filteredOrders = filteredOrders.filter(order => {
        let orderDate = new Date(order.date.slice(0, 10));
        if (
          dateMethods.inRange(
            orderDate,
            this.state.startDate,
            this.state.endDate
          )
        ) {
          return order;
        }
        return null;
      });
    }

    const orders = filteredOrders.map(order => (
      <UpdateOrdersRow key={order.id} order={order} />
    ));
    return (
      <div className="row">
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">Filter By Date</span>
          </div>
          <DatePicker
            className="form-control"
            selected={this.state.startDate}
            onChange={this.handleChangeStart}
            selectsStart
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          />
          <div className="input-group-prepend">
            <span className="input-group-text">To</span>
          </div>

          <DatePicker
            className="form-control"
            selected={this.state.endDate}
            onChange={this.handleChangeEnd}
            selectsEnd
            startDate={this.state.startDate}
            endDate={this.state.endDate}

            //       <div>
            //         <div className="row">
            //           <h4 className="mr-1 col-lg-1 col-sm-12">From: </h4>
            //           <input
            //             className="mx-1 form-control col-lg-1 col-sm-3  "
            //             type="search"
            //             name="startDay"
            //             placeholder="Start Day:"
            //             onChange={e => this.changeHandler(e)}
            //           />
            //           <input
            //             className="mx-1 form-control col-lg-1 col-sm-3  "
            //             type="search"
            //             name="startMonth"
            //             placeholder="Start Month:"
            //             onChange={e => this.changeHandler(e)}
            //           />
            //           <input
            //             className="mx-1 form-control col-lg-1 col-sm-3  "
            //             type="search"
            //             name="startYear"
            //             placeholder="Start Year:"
            //             onChange={e => this.changeHandler(e)}
            //           />
            //           <h4 className="col-lg-1 col-sm-12 mr-1 text-lg-right">To:</h4>
            //           <input
            //             className="mx-1 form-control col-lg-1 col-sm-3  "
            //             type="search"
            //             name="endDay"
            //             placeholder="End Day:"
            //             onChange={e => this.changeHandler(e)}
            //           />
            //           <input
            //             className="mx-1 form-control col-lg-1 col-sm-3  "
            //             type="search"
            //             name="endMonth"
            //             placeholder="End Month:"
            //             onChange={e => this.changeHandler(e)}
            //           />
            //           <input
            //             className="mx-1 form-control col-lg-1 col-sm-3  "
            //             type="search"
            //             name="endYear"
            //             placeholder="End Year:"
            //             onChange={e => this.changeHandler(e)}
          />
          <div className="input-group-prepend" onClick={this.clearDateFilter}>
            <button className="input-group-text btn btn-warning">
              Clear Dates
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="mt-3 table">
            <thead>
              <tr>
                <th className="text-success">Order Id</th>
                <th className="text-success">Order Time</th>
                <th className="text-success">Order Date</th>
                <th className="text-success">Order Status</th>
                <th className="text-success">Order Branch</th>
                <th className="text-success">Order Driver</th>
                <th className="text-success">Order Total</th>
                <th className="text-success text-center">
                  Order Status Update
                </th>
              </tr>
            </thead>
            <tbody>{orders}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default WaiterOrderTable;
