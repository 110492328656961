// Actions
import * as actionCreators from "../../store/actions";

import React, { Component } from "react";

import { connect } from "react-redux";

class EditStockOrder extends Component {
  state = {
    id: this.props.location.state.order.id,
    status: this.props.location.state.order.status,
    driver: this.props.location.state.order.driver,
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = async (event) => {
    await this.props.updateStockOrder(this.state);
    this.props.history.push("/stockorders");
  };

  render() {
    let drivers = [
      "None",
      "Qassim",
      "Faheem",
      "Jifan",
      "Shasika",
      "Erfan",
      "Haider",
      "Bandar",
      "Arsan",
      "Fawzar",
      "Saddeeq",
      "Refas",
      "Rawan",
      "Rafenda",
      "Rakshan",
      "AliHassan",
      "MohammedOmair",
    ];
    let driversList = drivers.map((driver) => {
      return (
        <option key={driver} value={driver}>
          {driver}
        </option>
      );
    });
    return (
      <div className="card col-6 mx-auto p-0 mt-5">
        <div className="card-body">
          <div className="row">
            <form className="input-group mb-3" onSubmit={this.handleSubmit}>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Status</span>
                </div>
                <select
                  name="status"
                  value={this.state.status}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option value="Cart">Cart</option>
                  <option value="Ordered">Ordered</option>
                  <option value="Proccessing">Proccessing</option>
                  <option value="Out For Delivery">Out For Delivery</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Dine In">Dine In</option>
                </select>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Driver</span>
                </div>
                <select
                  name="driver"
                  value={this.state.driver}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  {driversList}
                </select>
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stockItems: state.stockCart.orders,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateStockOrder: (order) => dispatch(actionCreators.updateStockOrder(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditStockOrder);
