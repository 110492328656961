import * as actionTypes from "../actions/actionTypes";

const initialState = {
  items: [],
  varients: [],
  loading: true
};
const itemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ITEMS:
      return {
        ...state,
        items: action.payload,
        loading: false
      };
    case actionTypes.FETCH_VARIENTS:
      return {
        ...state,
        varients: action.payload
      };
    // case actionTypes.SET_STOCK:
    //   return {
    //     ...state,
    //     items: state.items.filter(item => {
    //       if (item.id === action.payload.id) {
    //         item.stock = action.payload.stock;
    //       }
    //       return item;
    //     })
    //   };
    default:
      return state;
  }
};

export default itemsReducer;
