import { combineReducers } from "redux";

// Reducers
import authReducer from "./authReducer";
import itemsReducer from "./itemsReducer";
import itemReducer from "./itemReducer";
import cartReducer from "./cartReducer";
import customersReducer from "./customersReducer";
import stockCartReducer from "./stockCartReducer";
import spendReducer from "./spendReducer";
import reportReducer from "./reportReducer";

export default combineReducers({
  auth: authReducer,
  items: itemsReducer,
  prof: authReducer,
  item: itemReducer,
  cart: cartReducer,
  cust: customersReducer,
  stockCart: stockCartReducer,
  spend: spendReducer,
  report: reportReducer
});
