import React, { Component } from "react";
import StockOrderItemsRow from "./StockOrderItemsRow";

class StockOrderItemsTable extends Component {
  showEdit = () => {
    if (
      this.props.orderStatus === "Delivered" ||
      this.props.orderStatus === "Cancelled"
    ) {
      return null;
    } else {
      return <th>Edit</th>;
    }
  };
  render() {
    let stockItemsList = this.props.stockItems.map(stockItem => (
      <StockOrderItemsRow
        key={stockItem.id}
        stockItem={stockItem}
        orderStatus={this.props.orderStatus}
      />
    ));

    return (
      <div className=" card border-0 p-3 mt-3 ">
        <table className="mt-3 table">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Category</th>
              <th>Quantity</th>
              <th>Notes</th>
              {this.showEdit()}
            </tr>
          </thead>
          <tbody>{stockItemsList}</tbody>
        </table>
      </div>
    );
  }
}

export default StockOrderItemsTable;
