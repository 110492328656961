// Actions
import * as actionCreators from "../../store/actions";

import React, { Component } from "react";

import { connect } from "react-redux";

class NewStockOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "Cart",

      driver: "يرجى اختيار السائق",
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async submitHandler() {
    if (this.state.driver === "يرجى اختيار السائق") {
      alert("يرجى التاكد من ادخال جميع البينات");
    } else {
      await this.props.createStockOrder(
        {
          status: this.state.status,
          driver: this.state.driver,
        },
        this.props.history
      );
      this.props.history.push("/stockitems");
    }
  }

  setDriver = (driver) => {
    this.setState({ driver: driver });
  };

  render() {
    if (!this.props.user) {
      this.props.history.push("/login");
    }

    let drivers = [
      "None",
      "Qassim",
      "Faheem",
      "Jifan",
      "Shasika",
      "Erfan",
      "Haider",
      "Bandar",
      "Arsan",
      "Fawzar",
      "Saddeeq",
      "Refas",
      "Rafenda",
      "Rawan",
      "Rakshan",
      "AliHassan",
      "MohammedOmair",
    ];
    let driverList = drivers.map((driver) => {
      return (
        <a
          className="dropdown-item"
          key={driver}
          onClick={() => this.setDriver(driver)}
        >
          {driver}
        </a>
      );
    });

    return (
      <div className="card col-6 mx-auto p-0 mt-5">
        <div className="card-body">
          <h5 className="row ">
            <div className="dropdown mx-auto">
              <button
                className="btn btn-light dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {this.state.driver}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {driverList}
              </div>
            </div>
          </h5>
          <div className="row">
            <button
              className="btn btn-outline-success mx-auto col-md-6 col-sm-11"
              onClick={() => this.submitHandler()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  profile: state.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({
  createStockOrder: (order, history) =>
    dispatch(actionCreators.createStockOrder(order, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewStockOrder);
