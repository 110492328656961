import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";

// Actions
import * as actionCreators from "../store/actions";

class RegisterOrLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitHandler(e) {
    const type = this.props.match.url.substring(1);
    e.preventDefault();
    if (type === "login") {
      this.props.login(this.state, this.props.history);
    }
  }

  render() {
    const type = this.props.match.url.substring(1);

    if (this.props.user) {
      return <Redirect to="/home" />;
    }
    return (
      <div className="card col-lg-6 col-md-10 col-sm-11 mx-auto p-0 mt-5">
        <div className="card-body">
          <h5 className="card-title mb-4">
            {type === "login"
              ? "Login to send messages"
              : "Register an account"}
          </h5>
          <form onSubmit={this.submitHandler} noValidate>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Username"
                name="username"
                required
                onChange={this.changeHandler}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="password"
                placeholder="Password"
                name="password"
                required
                onChange={this.changeHandler}
              />
            </div>
            <input
              className="btn btn-primary col-lg-2 col-md-6 col-sm-12 float-right"
              type="submit"
              value={type.replace(/^\w/, c => c.toUpperCase())}
            />
          </form>
        </div>
        <div className="card-footer" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  login: (userData, history) =>
    dispatch(actionCreators.login(userData, history))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterOrLogin);
