import React, { Component } from "react";

// Components

import { connect } from "react-redux";

class dailyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.loading) {
      let mangafKnet = 0.0;
      let mangafCash = 0.0;
      let fintas1Knet = 0.0;
      let fintas1Cash = 0.0;
      let fintas2Knet = 0.0;
      let fintas2Cash = 0.0;
      let sulaibiyaKnet = 0.0;
      let sulaibiyaCash = 0.0;
      let totalCash = 0.0;
      let totalKnet = 0.0;
      let totalIncome = 0.0;

      let mangafSpending = 0.0;
      let mangafGrocery = 0.0;
      let mangafStock = 0.0;
      let fintas1Spending = 0.0;
      let fintas1Grocery = 0.0;
      let fintas1Stock = 0.0;
      let fintas2Spending = 0.0;
      let fintas2Grocery = 0.0;
      let fintas2Stock = 0.0;
      let sulaibiyaSpending = 0.0;
      let sulaibiyaGrocery = 0.0;
      let sulaibiyaStock = 0.0;
      let totalStock = 0.0;
      let totalSpending = 0.0;
      let totalGrocery = 0.0;

      let fintas1Expenses = 0.0;
      let fintas2Expenses = 0.0;
      let mangafExpenses = 0.0;
      let sulaibiyaExpenses = 0.0;
      let totalExpenses = 0.0;

      if (this.props.orders.cash) {
        this.props.orders.cash.forEach(branch => {
          if (branch.branch === "Mangaf") {
            mangafCash = branch.total;
          } else if (branch.branch === "Fintas 1") {
            fintas1Cash = branch.total;
          } else if (branch.branch === "Fintas 2") {
            fintas2Cash = branch.total;
          } else if (branch.branch === "Sulaibiya") {
            sulaibiyaCash = branch.total;
          }
          totalCash += branch.total;
          totalIncome += branch.total;
        });
      }

      if (this.props.orders.knet) {
        this.props.orders.knet.forEach(branch => {
          if (branch.branch === "Mangaf") {
            mangafKnet = branch.total;
          } else if (branch.branch === "Fintas 1") {
            fintas1Knet = branch.total;
          } else if (branch.branch === "Fintas 2") {
            fintas2Knet = branch.total;
          } else if (branch.branch === "Sulaibiya") {
            sulaibiyaKnet = branch.total;
          }
          totalKnet += branch.total;
          totalIncome += branch.total;
        });
      }

      this.props.stockOrders.forEach(result => {
        switch (result.branch) {
          case "Mangaf":
            mangafStock = result.total;
            break;
          case "Fintas 1":
            fintas1Stock = result.total;
            break;
          case "Fintas 2":
            fintas2Stock = result.total;
            break;
          case "Sulaibiya":
            sulaibiyaStock = result.total;
            break;
          default:
            break;
        }
        totalStock += result.total;
      });

      this.props.groceries.forEach(result => {
        switch (result.branch) {
          case "Mangaf":
            mangafGrocery = result.total;
            break;
          case "Fintas 1":
            fintas1Grocery = result.total;
            break;
          case "Fintas 2":
            fintas2Grocery = result.total;
            break;
          case "Sulaibiya":
            sulaibiyaGrocery = result.total;
            break;
          default:
            break;
        }
        totalGrocery += result.total;
      });

      this.props.spendings.forEach(result => {
        switch (result.branch) {
          case "Mangaf":
            mangafSpending = result.total;
            break;
          case "Fintas 1":
            fintas1Spending = result.total;
            break;
          case "Fintas 2":
            fintas2Spending = result.total;
            break;
          case "Sulaibiya":
            sulaibiyaSpending = result.total;
            break;
          default:
            break;
        }
        totalSpending += result.total;
      });

      this.props.expenses.forEach(result => {
        switch (result.branch) {
          case "Mangaf":
            mangafExpenses = result.total;
            break;
          case "Fintas 1":
            fintas1Expenses = result.total;
            break;
          case "Fintas 2":
            fintas2Expenses = result.total;
            break;
          case "Sulaibiya":
            sulaibiyaExpenses = result.total;
            break;

          default:
            break;
        }
        totalExpenses += result.total;
      });
      let range = this.props.location.state.range;
      return (
        <div className="card col-11 m-auto">
          <div className="card-body">
            <h1 className="card-title float-right">كشف الايرادات</h1>
            <br></br>
            <div className="row mt-5 ">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                <h6>
                  الوقت من: {range.start_hour} الى: {range.end_hour}
                </h6>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                <h6>
                  التاريخ من: {range.start_date} الى: {range.end_date}
                </h6>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="table-success">
                    <th scope="col" className="text-center">
                      Cash(KWD)
                    </th>
                    <th scope="col" className="text-center">
                      K-net(KWD)
                    </th>
                    <th scope="col" className="text-right">
                      الفرع
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{mangafCash.toFixed(3)}</td>
                    <td className="text-center">{mangafKnet.toFixed(3)}</td>
                    <th className="text-right">طلبات فرع المنقف</th>
                  </tr>
                  <tr>
                    <td className="text-center">{fintas1Cash.toFixed(3)}</td>
                    <td className="text-center">{fintas1Knet.toFixed(3)}</td>
                    <th className="text-right">طلبات فرع الفنطاس ١</th>
                  </tr>
                  <tr>
                    <td className="text-center">{fintas2Cash.toFixed(3)}</td>
                    <td className="text-center">{fintas2Knet.toFixed(3)}</td>
                    <th className="text-right">طلبات فرع الفنطاس ٢</th>
                  </tr>
                  <tr>
                    <td className="text-center">{sulaibiyaCash.toFixed(3)}</td>
                    <td className="text-center">{sulaibiyaKnet.toFixed(3)}</td>
                    <th className="text-right">طلبات فرع صليبيه</th>
                  </tr>
                  <tr className="table-dark">
                    <td className="text-center">{totalCash.toFixed(3)}</td>
                    <td className="text-center">{totalKnet.toFixed(3)}</td>
                    <th className="text-right">المجموع</th>
                  </tr>
                  <tr className="table-success">
                    <td className="text-center">
                      {" "}
                      KWD {totalIncome.toFixed(3)}
                    </td>
                    <th className="text-right">:مجموع الإيرادات </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <h1 className="card-title float-right">كشف طلبات المخزن</h1>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="table-success">
                    <th scope="col" className="text-center">
                      صرف من المخزن
                    </th>
                    <th scope="col" className="text-right">
                      الفرع
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{mangafStock.toFixed(3)}</td>
                    <th className="text-right">طلبات فرع المنقف</th>
                  </tr>
                  <tr>
                    <td className="text-center">{fintas1Stock.toFixed(3)}</td>
                    <th className="text-right">طلبات فرع الفنطاس ١</th>
                  </tr>
                  <tr>
                    <td className="text-center">{fintas2Stock.toFixed(3)}</td>
                    <th className="text-right">طلبات فرع الفنطاس ٢</th>
                  </tr>
                  <tr>
                    <td className="text-center">{sulaibiyaStock.toFixed(3)}</td>
                    <th className="text-right">طلبات فرع صليبيه</th>
                  </tr>
                  <tr className="table-dark">
                    <td className="text-center">{totalStock.toFixed(3)}</td>
                    <th className="text-right">المجموع</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <h1 className="card-title float-right">كشف مصروفات الماجلة</h1>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="table-success">
                    <th scope="col" className="text-center">
                      ماجله الافرع
                    </th>
                    <th scope="col" className="text-right">
                      الفرع
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{mangafGrocery.toFixed(3)}</td>
                    <th className="text-right">ماجلة فرع المنقف</th>
                  </tr>
                  <tr>
                    <td className="text-center">{fintas1Grocery.toFixed(3)}</td>
                    <th className="text-right">ماجلة فرع الفنطاس ١</th>
                  </tr>
                  <tr>
                    <td className="text-center">{fintas2Grocery.toFixed(3)}</td>
                    <th className="text-right">ماجلة فرع الفنطاس ٢</th>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {sulaibiyaGrocery.toFixed(3)}
                    </td>
                    <th className="text-right">ماجلة فرع صليبيه</th>
                  </tr>
                  <tr className="table-dark">
                    <td className="text-center">{totalGrocery.toFixed(3)}</td>
                    <th className="text-right">المجموع</th>
                  </tr>
                </tbody>
              </table>
            </div>

            <h1 className="card-title float-right">كشف المصروفات</h1>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="table-success">
                    <th scope="col" className="text-center">
                      مصروفات الافرع
                    </th>
                    <th scope="col" className="text-right">
                      الفرع
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{mangafSpending.toFixed(3)}</td>
                    <th className="text-right">مصروفات فرع المنقف</th>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {fintas1Spending.toFixed(3)}
                    </td>
                    <th className="text-right">مصروفات فرع الفنطاس ١</th>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {fintas2Spending.toFixed(3)}
                    </td>
                    <th className="text-right">مصروفات فرع الفنطاس ٢</th>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {sulaibiyaSpending.toFixed(3)}
                    </td>
                    <th className="text-right">مصروفات فرع صليبيه</th>
                  </tr>
                  <tr className="table-dark">
                    <td className="text-center">{totalSpending.toFixed(3)}</td>
                    <th className="text-right">المجموع</th>
                  </tr>
                </tbody>
              </table>
            </div>

            <h1 className="card-title float-right">كشف المصروفات الشهرية</h1>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="table-success">
                    <th scope="col" className="text-center">
                      المصروفات الشهريه - اجارات و رواتب الخ
                    </th>
                    <th scope="col" className="text-right">
                      الفرع
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{mangafExpenses.toFixed(3)}</td>
                    <th className="text-right"> فرع المنقف</th>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {fintas1Expenses.toFixed(3)}
                    </td>
                    <th className="text-right"> فرع الفنطاس ١</th>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {fintas2Expenses.toFixed(3)}
                    </td>
                    <th className="text-right"> فرع الفنطاس ٢</th>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {sulaibiyaExpenses.toFixed(3)}
                    </td>
                    <th className="text-right"> فرع صليبيه</th>
                  </tr>
                  <tr className="table-dark">
                    <td className="text-center">{totalExpenses.toFixed(3)}</td>
                    <th className="text-right">المجموع</th>
                  </tr>
                </tbody>
              </table>

              <h1 className="card-title float-right">المستلم و الصافي</h1>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-success">
                      <th scope="col" className="text-center">
                        الصافي
                      </th>
                      <th scope="col" className="text-center">
                        المستلم
                      </th>
                      <th scope="col" className="text-right">
                        الفرع
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        {(mangafCash - mangafSpending - mangafGrocery).toFixed(
                          3
                        )}
                      </td>
                      <td className="text-center">
                        {(mangafCash - mangafSpending).toFixed(3)}
                      </td>
                      <th className="text-right"> فرع المنقف</th>
                    </tr>
                    <tr>
                      <td className="text-center">
                        {(
                          fintas1Cash -
                          fintas1Spending -
                          fintas1Grocery
                        ).toFixed(3)}
                      </td>
                      <td className="text-center">
                        {(fintas1Cash - fintas1Spending).toFixed(3)}
                      </td>
                      <th className="text-right"> فرع الفنطاس ١</th>
                    </tr>
                    <tr>
                      <td className="text-center">
                        {(
                          fintas2Cash -
                          fintas2Spending -
                          fintas2Grocery
                        ).toFixed(3)}
                      </td>
                      <td className="text-center">
                        {(fintas2Cash - fintas2Spending).toFixed(3)}
                      </td>
                      <th className="text-right"> فرع الفنطاس ٢</th>
                    </tr>
                    <tr>
                      <td className="text-center">
                        {(
                          sulaibiyaCash -
                          sulaibiyaSpending -
                          sulaibiyaGrocery
                        ).toFixed(3)}
                      </td>
                      <td className="text-center">
                        {(sulaibiyaCash - sulaibiyaSpending).toFixed(3)}
                      </td>
                      <th className="text-right"> فرع صليبيه </th>
                    </tr>
                    <tr className="table-dark">
                      <td className="text-center">
                        {(totalCash - totalSpending - totalGrocery).toFixed(3)}
                      </td>
                      <td className="text-center">
                        {(totalCash - totalSpending).toFixed(3)}
                      </td>
                      <th className="text-right">المجموع</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  loading: state.report.loading,
  orders: state.report.orders,
  stockOrders: state.report.stockOrders,
  groceries: state.report.groceries,
  spendings: state.report.spendings,
  expenses: state.report.expenses
});
const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(dailyReport);
