import React, { Component } from "react";
import { connect } from "react-redux";
import StockTable from "./StockTable";
import * as actionCreators from "../../store/actions";

class StockCart extends Component {
  submitOrder = () => {
    let stockOrder = {
      id: this.props.stockCart.id,
      status: "Ordered",
      driver: this.props.stockCart.driver
    };
    this.props.updateStock(stockOrder);
  };
  render() {
    return (
      <div>
        <h2>Stock Cart</h2>
        <StockTable />
        <div className="text-center">
          <button
            className="btn btn-lg btn-block btn-outline-success"
            style={{ textAligh: "center" }}
            onClick={() => this.submitOrder()}
          >
            تأكيد الطلب
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  stockCart: state.stockCart.cart
});
const mapDispatchToProps = dispatch => ({
  updateStock: stockOrder =>
    dispatch(actionCreators.updateStockOrder(stockOrder))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockCart);
