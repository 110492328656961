import * as actionTypes from "./actionTypes";
import instance from "./instance";

export const fetchSpendings = () => {
  return dispatch => {
    instance
      .get("api/spending/")
      .then(res => res.data)
      .then(spendings => {
        dispatch({ type: actionTypes.FETCH_SPENDINGS, payload: spendings });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const fetchExpenses = () => {
  return dispatch => {
    instance
      .get("api/expense/")
      .then(res => res.data)
      .then(expenses => {
        dispatch({ type: actionTypes.FETCH_EXPENSES, payload: expenses });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const fetchInvoices = () => {
  return dispatch => {
    instance
      .get("api/invoice/")
      .then(res => res.data)
      .then(invoices => {
        dispatch({ type: actionTypes.FETCH_INVOICES, payload: invoices });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};
export const fetchGroceries = () => {
  return dispatch => {
    instance
      .get("api/grocery/")
      .then(res => res.data)
      .then(groceries => {
        dispatch({ type: actionTypes.FETCH_GROCERIES, payload: groceries });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const createSpending = (spending, history) => {
  return dispatch => {
    instance
      .post("api/spending/", spending)
      .then(res => res.data)
      .then(spending => {
        dispatch({
          type: actionTypes.ADD_SPENDING,
          payload: spending
        });
      })
      .then(() => history.push("/home"))
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const createInvoice = (invoice, history) => {
  return dispatch => {
    instance
      .post("api/invoice/", invoice)
      .then(res => res.data)
      .then(invoice => {
        dispatch({
          type: actionTypes.ADD_INVOICE,
          payload: invoice
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};

export const createGrocery = (grocery, history) => {
  return dispatch => {
    instance
      .post("api/grocery/", grocery)
      .then(res => res.data)
      .then(grocery => {
        dispatch({
          type: actionTypes.ADD_GROCERY,
          payload: grocery
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
};
