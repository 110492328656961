export const FETCH_ITEMS = "FETCH_ITEMS";
export const FETCH_VARIENTS = "FETCH_VARIENTS";

// Cart Types
export const SET_CART = "SET_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const CHECKOUT = "CHECKOUT";
export const UPDATE_ORDERITEM = "UPDATE_ORDERITEM";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const DELETE_ORDERITEM = "DELETE_ORDERITEM";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_MONTH = "FETCH_ORDERS_MONTH";
export const FETCH_ORDERS_DAY = "FETCH_ORDERS_DAY";

// Spends
export const FETCH_SPENDINGS = "FETCH_SPENDINGS";
export const FETCH_GROCERIES = "FETCH_GROCERIES";
export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_EXPENSES = "FETCH_EXPENSES";
export const ADD_SPENDING = "ADD_SPENDING";
export const ADD_INVOICE = "ADD_INVOICE";
export const ADD_GROCERY = "ADD_GROCERY";

//Customers Types
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const SELECT_CUSTOMER = "SELECT_CUSTOMER";

// //address

//Auth Types
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

//Stock Types
export const FETCH_STOCKS = "FETCH_STOCKS";
export const ADD_STOCK = "ADD_STOCK";
export const UPDATE_STOCK = "UPDATE_STOCK";

//Stock Cart Types
export const FETCH_STOCK_ORDERS = "FETCH_STOCK_ORDERS";
export const FETCH_STOCK_ORDERS_BY_DAY = "FETCH_STOCK_ORDERS_BY_DAY";
export const ADD_TO_STOCK_CART = "ADD_TO_STOCK_CART";
export const STOCK_CHECKOUT = "STOCK_CHECKOUT";
export const SET_STOCK_CART = "SET_STOCK_CART";
export const UPDATE_STOCK_ORDER = "UPDATE_STOCK_ORDER";
export const ADD_STOCK_ITEM = "ADD_STOCK_ITEM";
export const UPDATE_STOCK_ORDER_ITEM = "UPDATE_STOCK_ORDER_ITEM";
export const DELETE_STOCKORDERITEM = "DELETE_STOCKORDERITEM";
export const SOUND = "SOUND";

//Report Types
export const FETCH_REPORT = "FETCH_REPORT";
export const FETCH_DAILY_REPORT = "FETCH_DAILY_REPORT";
