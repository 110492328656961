import * as actionTypes from "../actions/actionTypes";

const initialState = {
  customers: [],
  customer: {},
  loading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_CUSTOMER:
      return {
        ...state,
        customers: state.customers.concat(action.payload)
      };
    case actionTypes.UPDATE_CUSTOMER:
      let newCustomer = state.customers.find(
        customer => customer.id === action.payload.id
      );
      newCustomer = { ...action.payload };
      return {
        ...state
      };
    case actionTypes.FETCH_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
        customer: action.payload[0],
        loading: false
      };
    case actionTypes.SELECT_CUSTOMER:
      return {
        ...state,
        customer: action.payload
      };
    default:
      return state;
  }
}
