import React, { Component } from "react";
import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

class StockOrderItemsRow extends Component {
  state = {
    editMode: false,
    notes: this.props.stockItem.notes,
    quantity: this.props.stockItem.quantity
  };

  toggleEditMode = () => {
    let newValue = !this.state.editMode;
    this.setState({ editMode: newValue });
  };

  updateStockItem = () => {
    this.props.updateStockOrderItem({
      id: this.props.stockItem.id,
      stockOrder: this.props.stockItem.stockOrder,
      stockItem: this.props.stockItem.stockItem.id,
      quantity: this.state.quantity,
      notes: this.state.notes
    });
    this.toggleEditMode();
  };

  setButton = () => {
    if (
      this.props.orderStatus.toString() === "Delivered" ||
      this.props.orderStatus.toString() === "Cancelled"
    ) {
      return null;
    } else if (this.state.editMode) {
      return (
        <button className="btn btn-warning" onClick={this.updateStockItem}>
          Save
        </button>
      );
    } else {
      return (
        <button className="btn btn-dark" onClick={this.toggleEditMode}>
          Edit
        </button>
      );
    }
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { stockItem } = this.props;
    return (
      <tr>
        <td>{stockItem.stockItem.name}</td>
        <td>{stockItem.stockItem.category}</td>
        <td>
          {this.state.editMode ? (
            <input
              className="form-control"
              type="number"
              name="quantity"
              min="0"
              max={stockItem.stock}
              value={this.state.quantity}
              onChange={this.handleChange}
            />
          ) : (
            this.state.quantity
          )}
        </td>
        <td>
          {this.state.editMode ? (
            <input
              className="form-control"
              style={{ width: "80%" }}
              type="text"
              placeholder="Notes"
              value={this.state.notes}
              name="notes"
              onChange={this.handleChange}
            />
          ) : (
            this.state.notes
          )}
        </td>
        <td>{this.setButton()}</td>
      </tr>
    );
  }
}

const mapStateToProps = state => ({
  stockOrders: state.stockCart.orders
});
const mapDispatchToProps = dispatch => ({
  updateStockOrderItem: item =>
    dispatch(actionCreators.updateStockOrderItem(item))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockOrderItemsRow);
