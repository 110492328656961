import * as actionTypes from "../actions/actionTypes";
import Sound from "react-sound";
const initialState = {
  oldCart: null,
  cart: null,
  cartItems: [],
  orders: [],
  ordersMonth: [],
  ordersDay: [],
  ordersDayOld: [],
  diff: 0,
  play: Sound.status.STOPPED
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DELETE_ORDERITEM:
      let newItems = state.cart.orderItems.filter(
        orderItem => orderItem.id !== action.payload
      );
      state.cartItems = newItems;
      state.cart.orderItems = newItems;
      return {
        ...state
      };
    case actionTypes.UPDATE_ORDER:
      let newOrder = state.orders.find(order => order.id === action.payload.id);
      newOrder.status = action.payload.status;
      newOrder.source = action.payload.source;
      newOrder.payment = action.payload.payment;
      newOrder.customer = action.payload.customer;
      newOrder.driver = action.payload.driver;
      newOrder.discount = action.payload.discount;
      newOrder.deliveryCharges = action.payload.deliveryCharges;
      newOrder.branch = action.payload.branch;
      newOrder.total = action.payload.total;
      let newOrder2 = state.ordersDay.find(
        order => order.id === action.payload.id
      );
      newOrder2.status = action.payload.status;
      newOrder2.source = action.payload.source;
      newOrder2.payment = action.payload.payment;
      newOrder2.customer = action.payload.customer;
      newOrder2.driver = action.payload.driver;
      newOrder2.discount = action.payload.discount;
      newOrder2.deliveryCharges = action.payload.deliveryCharges;
      newOrder2.branch = action.payload.branch;
      newOrder2.total = action.payload.total;
      if (newOrder.status === "Delivered" || newOrder.status === "Ordered") {
        return {
          ...state,
          oldCart: state.cart,
          cart: null
        };
      } else {
        return {
          ...state
        };
      }
    case actionTypes.UPDATE_ORDERITEM:
      let newItem = state.cart.orderItems.find(
        orderItem => orderItem.id === action.payload.id
      );
      newItem.quantity = action.payload.quantity;
      newItem.notes = action.payload.notes;
      newItem.addOns = action.payload.addOns;
      let cartItem = state.cartItems.find(
        cartItem => cartItem.id === action.payload.id
      );
      cartItem.quantity = action.payload.quantity;
      cartItem.notes = action.payload.notes;
      return {
        ...state,
        cart: state.cart,
        cartItems: [...state.cartItems]
      };
    case actionTypes.CHECKOUT:
      return {
        ...state,
        cartItems: []
      };
    case actionTypes.SET_CART:
      state.orders = state.orders.concat(action.payload);
      state.ordersDay = state.ordersDay.concat(action.payload);
      state.ordersMonth = state.ordersMonth.concat(action.payload);
      return {
        ...state,
        cart: action.payload,
        oldCart: action.payload,
        cartItems: action.payload.orderItems
      };
    case actionTypes.ADD_TO_CART:
      state.cart.orderItems = state.cart.orderItems.concat(action.payload);
      let order = state.orders.find(order => order.id === state.cart.id);
      order.orderItems.concat(action.payload);
      let orderM = state.ordersMonth.find(order => order.id === state.cart.id);
      orderM.orderItems.concat(action.payload);
      let orderD = state.ordersDay.find(order => order.id === state.cart.id);
      orderD.orderItems.concat(action.payload);
      return {
        ...state,
        cartItems: state.cartItems.concat(action.payload),
        cart: state.cart
      };
    case actionTypes.FETCH_ORDERS:
      return {
        ...state,
        orders: action.payload
      };
    case actionTypes.FETCH_ORDERS_MONTH:
      return {
        ...state,
        ordersMonth: action.payload
      };
    case actionTypes.FETCH_ORDERS_DAY:
      return {
        ...state,
        ordersDayOld: state.ordersDay,
        ordersDay: action.payload,
        diff: state.ordersDay.length - state.ordersDayOld.length
      };
    case actionTypes.SOUND:
      return {
        ...state,
        play: action.payload
      };
    default:
      return state;
  }
}
