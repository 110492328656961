import * as actionTypes from "../actions/actionTypes";

const initialState = {
  orders: {
    cash: [],
    knet: []
  },
  ordersCancelled: {
    cash: [],
    knet: []
  },
  stockOrders: [],
  groceries: [],
  spendings: [],
  expenses: [],
  ordersD: {},
  stockOrdersD: {},
  groceriesD: {},
  spendingsD: {},
  loading: true,
  loadingD: true
};
const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REPORT:
      return {
        ...state,
        orders: action.payload.orders,
        stockOrders: action.payload.stock_orders,
        groceries: action.payload.groceries,
        spendings: action.payload.spendings,
        expenses: action.payload.expenses,
        loading: false
      };
    case actionTypes.FETCH_DAILY_REPORT:
      return {
        ...state,
        ordersD: action.payload.orders,
        ordersCancelled: action.payload.ordersCancelled,
        stockOrdersD: action.payload.stock_orders,
        groceriesD: action.payload.groceries,
        spendingsD: action.payload.spendings,
        loadingD: false
      };
    default:
      return state;
  }
};

export default reportReducer;
