import React, { Component } from "react";
import { connect } from "react-redux";

import OrderItemRow from "./OrderItemRow";

class OrderItemTable extends Component {
  render() {
    if (this.props.order) {
      let total = 0;
      const orderRow = this.props.order.orderItems.map(orderItem => {
        total += orderItem.quantity * orderItem.total;
        return <OrderItemRow key={orderItem.id} orderItem={orderItem} />;
      });
      let totalWDelivery =
        parseFloat(this.props.order.total) +
        parseFloat(this.props.deliveryCharges);
      return (
        <div className="">
          <table className="mt-3 table col-12">
            <thead>
              <tr>
                <td className="font-weight-bold sm-font-size">Item</td>
                <td className="font-weight-bold sm-font-size">Quantity</td>
                <td className="font-weight-bold sm-font-size">Price</td>
                <td className="font-weight-bold sm-font-size">Total</td>
              </tr>
            </thead>
            <tbody>{orderRow}</tbody>
          </table>

          <table className="table col-12" align="right">
            <tbody>
              <tr>
                <td className="text-right">{this.props.order.discount}%</td>
                <td className="text-right">الخصم</td>
              </tr>
              <tr>
                <td className="text-right">{parseFloat(total).toFixed(3)}</td>
                <td className="text-right">المجموع</td>
              </tr>
              <tr>
                <td className="text-right">
                  {parseFloat(this.props.deliveryCharges).toFixed(3)}
                </td>
                <td className="text-right">رسوم التوصيل</td>
              </tr>
              <tr className="table-success">
                <td className="text-right">{totalWDelivery.toFixed(3)}</td>
                <td className="text-right">الاجمالي</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div>
          <h1 className="black-title">Loading!!!</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart.cart,
    varients: state.items.varients
  };
};
export default connect(mapStateToProps)(OrderItemTable);
