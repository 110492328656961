import React, { Component } from "react";
// Components
import UpdateOrdersTable from "./updateOrdersTable";
import Sound from "react-sound";
import { connect } from "react-redux";
import WaiterOrders from "../OrdersTracking/WaiterOrder";
import * as actionCreators from "../../store/actions";
import audioAlert from "../../alert/alert_tones.mp3";
import Countdown from "react-countdown-now";

class updateOrders extends Component {
  timer = 0;
  state = {
    play: this.props.play,
    key: 0
  };
  componentDidMount() {
    this.props.fetchToday();
    this.timer = setInterval(() => {
      this.setState({ key: this.state.key + 1 });
      this.props.fetchToday();
    }, 300000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }
  componentDidUpdate(prevState) {
    if (this.props.profile) {
      let prevOrders = prevState.orders.filter(
        order => order.branch === this.props.profile.branch
      );
      let newOrders = this.props.orders.filter(
        order => order.branch === this.props.profile.branch
      );
      if (prevOrders.length === 0 && newOrders.length > 0) {
        this.props.soundplay(Sound.status.PLAYING);
      } else if (prevOrders.length > 0 && newOrders.length > 0) {
        if (
          !prevOrders.find(
            order => order.id === newOrders[newOrders.length - 1].id
          )
        ) {
          this.props.soundplay(Sound.status.PLAYING);
        }
      }
    }
  }

  render() {
    if (this.props.orders && this.props.profile) {
      let branchOrders = this.props.orders.filter(
        order =>
          order.branch === this.props.profile.branch &&
          order.source !== "Waiter"
      );

      return (
        <div>
          <div className="card mx-auto col-11">
            <Sound url={audioAlert} playStatus={this.props.play} loop={true} />
            <div className="card-body">
              <div className="row">
                <div className="col-12 text-center">
                  <Countdown key={this.state.key} date={Date.now() + 300000} />
                </div>
              </div>
              <h1 className="text-black text-center "> طلبات الفرع </h1>
              {branchOrders.length !== 0 ? (
                <UpdateOrdersTable orders={branchOrders} />
              ) : (
                <p className="text-center text-danger"> No Orders </p>
              )}
            </div>
            <button
              className="btn btn-danger"
              onClick={() => this.props.soundplay(Sound.status.STOPPED)}
            >
              الغاء الصوت
            </button>
          </div>
          <WaiterOrders />
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading</h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  orderItems: state.cart.cartItems,
  cart: state.cart.cart,
  customers: state.cust.customers,
  orders: state.cart.ordersDay,
  oldOrders: state.cart.ordersDayOld,
  diff: state.cart.diff,
  profile: state.auth.profile,
  play: state.cart.play
});
const mapDispatchToProps = dispatch => ({
  fetchProfile: () => dispatch(actionCreators.fetchProfile()),
  fetchToday: () => dispatch(actionCreators.fetchOrdersDay()),
  soundplay: sound => dispatch(actionCreators.soundplay(sound))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(updateOrders);
