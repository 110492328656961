import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Components
import dateMethods from "../../store/actions/dateMethods";
import GroceryRow from "./GroceryRow";

class GroceryTable extends Component {
  state = {
    allStockOrders: true,
    startDate: new Date(),
    endDate: new Date()
  };

  handleChangeStart = date => {
    this.setState({
      startDate: date,
      allStockOrders: false
    });
  };

  handleChangeEnd = date => {
    this.setState({
      endDate: date,
      allStockOrders: false
    });
  };

  clearDateFilter = () => {
    this.setState({
      allStockOrders: true
    });
  };

  render() {
    let filteredItems = this.props.groceries;

    if (!this.state.allStockOrders) {
      filteredItems = filteredItems.filter(order => {
        let orderDate = new Date(order.date.slice(0, 10));
        if (
          dateMethods.inRange(
            orderDate,
            this.state.startDate,
            this.state.endDate
          )
        ) {
          return order;
        }
        return null;
      });
    }

    let groceries = filteredItems.map(grocery => (
      <GroceryRow key={grocery.id} grocery={grocery} />
    ));
    return (
      <div>
        <div className="row">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">Filter By Date</span>
            </div>
            <DatePicker
              className="form-control"
              selected={this.state.startDate}
              onChange={this.handleChangeStart}
              selectsStart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            <div className="input-group-prepend">
              <span className="input-group-text">To</span>
            </div>

            <DatePicker
              className="form-control"
              selected={this.state.endDate}
              onChange={this.handleChangeEnd}
              selectsEnd
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            <div className="input-group-prepend" onClick={this.clearDateFilter}>
              <button className="input-group-text btn btn-warning">
                Clear Dates
              </button>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="mt-3 table">
            <thead>
              <tr>
                <th className="text-success">Grocery Id</th>
                <th className="text-success">Grocery Name</th>
                <th className="text-success">Grocery Branch</th>
                <th className="text-success">Grocery Invoice Number</th>
                <th className="text-success">Grocery Username</th>
                <th className="text-success">Grocery Time</th>
                <th className="text-success">Grocery Date</th>
                <th className="text-success">Grocery Total</th>
              </tr>
            </thead>
            <tbody>{groceries}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default GroceryTable;
