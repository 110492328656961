import React, { Component } from "react";

// Components
import { connect } from "react-redux";

import * as actionCreators from "../../store/actions";

class HomePage extends Component {
  async componentDidMount() {
    if (this.props.profile) {
      this.props.fetchExpenses();
      if (this.props.profile.position === "Waiter" && !this.props.cart) {
        let order = {
          status: "Cart",
          customer: 1,
          branch: this.props.profile.branch,
          driver: "None",
          payment: "Cash",
          source: "Waiter",
          discount: 0.0,
          deliveryCharges: 0.0
        };
        await this.props.waiterCreateOrder(order, this.props.history);
      }
    }
  }

  async componentDidUpdate(prevState) {
    if (!this.props.profile) return this.props.history.replace("/");
    if (prevState.profile !== this.props.profile) {
      this.props.fetchExpenses();
      if (this.props.profile.position === "Waiter" && !this.props.cart) {
        let order = {
          status: "Cart",
          customer: 1,
          branch: this.props.profile.branch,
          driver: "None",
          payment: "Cash",
          source: "Waiter",
          discount: 0.0,
          deliveryCharges: 0.0
        };
        await this.props.waiterCreateOrder(order, this.props.history);
      }
    }
  }

  async createOrder() {
    if (this.props.profile.position === "Cashier") {
      let order = {
        status: "Cart",
        customer: 1,
        branch: this.props.profile.branch,
        driver: "None",
        payment: "Cash",
        source: "Restaurant",
        discount: 0.0,
        deliveryCharges: 0.0
      };
      await this.props.createOrder(order, this.props.history);
    } else if (this.props.profile.position === "Waiter") {
      let order = {
        status: "Cart",
        customer: 1,
        branch: this.props.profile.branch,
        driver: "None",
        payment: "Cash",
        source: "Waiter",
        discount: 0.0,
        deliveryCharges: 0.0
      };
      await this.props.createOrder(order, this.props.history);
      this.props.history.push("/list");
    } else {
      this.props.history.push("/neworder");
    }
  }
  casheirButtons = (
    <div className="card p-0">
      <h2 className="card-header bg-light text-success">الكاشير</h2>
      <div className="card-body">
        <button
          onClick={() => this.createOrder()}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3  text-center "
        >
          <h2>طلب جديد</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/newstockorder")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3  text-center "
        >
          <h2>طلب ستوكات جديد</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/updateorders")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3 "
        >
          <h2>طلبات اليوم</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/ordertrack/branch")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3 "
        >
          <h2>متابعة كل طلبات الفرع </h2>
        </button>
        <button
          onClick={() => this.props.history.push("/cleanup")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3 "
        >
          <h2>الطلبات المعلقة</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/stockordersbranch")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3"
        >
          <h2>طلبات ستوكات الفرع</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/newspending")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3"
        >
          <h2>تسجيل مصروفات</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/newgrocery")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3"
        >
          <h2>تسجيل ماجله</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/stockcleanup")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3"
        >
          <h2>طلبات الستوك الغير مكتملة</h2>
        </button>
      </div>
    </div>
  );
  waiterButtons = (
    <div className="card p-0">
      <h2 className="card-header bg-light text-success">الجرسون</h2>
      <div className="card-body">
        <button
          onClick={() => this.props.history.push("/list")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3  text-center "
        >
          <h2>طلب جديد</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/waiterordertrack")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3 "
        >
          <h2>متابعة طلباتي قيد التنفيذ</h2>
        </button>
      </div>
    </div>
  );
  callCenterButtons = (
    <div className="card p-0">
      <h2 className="card-header bg-light text-success">الكول سنتر</h2>
      <div className="card-body">
        <button
          onClick={() => this.createOrder()}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3  text-center "
        >
          <h2>طلب جديد</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/ordertrackcc")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3 "
        >
          <h2>متابعة كل الطلبات</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/cleanup")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3 "
        >
          <h2>الطلبات المعلقة</h2>
        </button>
      </div>
    </div>
  );

  accountantButtons = (
    <div className="card p-0">
      <h2 className="card-header bg-light text-success">المحاسب</h2>
      <div className="card-body">
        <button
          onClick={() => this.props.history.push("/ordertrackcc")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3 "
        >
          <h2>متابعة كل الطلبات</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/promtdate")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3 "
        >
          <h2>كشف مالي مختصر</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/spendings")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 mx-2 my-3 "
        >
          <h2>متابعة مصروفات الافرع</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/groceries")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11 m-2 "
        >
          <h2>متابعة ماجلة الافرع</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/stockorders/available")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3"
        >
          <h2>متابعة الستوكات</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/stockorders")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3"
        >
          <h2>طلبات الستوكات</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/stockorders/today")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3"
        >
          <h2>طلبات اليوم للستوكات</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/promtfordaily")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3"
        >
          <h2>كشف مالي مختصر لفرع واحد</h2>
        </button>
        <h2 className="card-header bg-light text-success">
          تحميل الطلبات القديمة
        </h2>
        <div className="card-body text-center">
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/orders/">
              تحميل كل الطلبات القديمه
            </a>
          </button>
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/stockorders/">
              تحميل كل طلبات الستوكات القديمه
            </a>
          </button>
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/spendings/">
              تحميل كل المصروفات القديمه
            </a>
          </button>
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/groceries/">
              تحميل كل الماجلة القديمه
            </a>
          </button>
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/expenses/">
              تحميل كل المصروفات الشهرية القديمه
            </a>
          </button>
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/invoices/">
              تحميل كل الفواتير
            </a>
          </button>
        </div>

        {/* <h2 className="card-header bg-light text-success">
          مسح الطلبات القديمة
        </h2>
        <div className="card-body text-center">
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/orders/">
              مسح كل الطلبات القديمه
            </a>
          </button>
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/stockorders/">
              مسح كل طلبات الستوكات القديمه
            </a>
          </button>
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/stockorders/">
              مسح كل طلبات الستوكات القديمه
            </a>
          </button>
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/stockorders/">
              مسح كل طلبات الستوكات القديمه
            </a>
          </button>
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/stockorders/">
              مسح كل طلبات الستوكات القديمه
            </a>
          </button>
          <button className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3">
            <a href="https://simplyjuice.thekhm.com/export/stockorders/">
              مسح كل طلبات الستوكات القديمه
            </a>
          </button>
        </div> */}
      </div>
    </div>
  );
  storeButtons = (
    <div className="card p-0">
      <h2 className="card-header bg-light text-successt">المخزن</h2>
      <div className="card-body">
        <button
          onClick={() => this.props.history.push("/stockorders/available")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3"
        >
          <h2>متابعة الستوكات</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/stockorders")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3"
        >
          <h2>طلبات الستوكات</h2>
        </button>
        <button
          onClick={() => this.props.history.push("/stockorders/today")}
          className="btn btn-outline-dark col-lg-3 col-md-4 col-xs-11  mx-2 my-3"
        >
          <h2>طلبات اليوم للستوكات</h2>
        </button>
      </div>
    </div>
  );

  adminButtons = (
    <div className="card p-0">
      {this.casheirButtons}
      {this.waiterButtons}
      {this.callCenterButtons}
      {this.accountantButtons}
    </div>
  );
  buttenChoice() {
    if (this.props.profile.position === "Admin") {
      return this.adminButtons;
    } else if (this.props.profile.position === "Cashier") {
      return this.casheirButtons;
    } else if (this.props.profile.position === "CallCenter") {
      return this.callCenterButtons;
    } else if (this.props.profile.position === "Store") {
      return this.storeButtons;
    } else if (this.props.profile.position === "Accountant") {
      return this.accountantButtons;
    } else if (this.props.profile.position === "Waiter") {
      return <h1>هذه الصفحه ليست لك</h1>;
    } else {
      return <h1>هذه الصفحه ليست لك</h1>;
    }
  }
  render() {
    if (!this.props.user) {
      this.props.history.push("/login");
    }
    if (this.props.profile) {
      return (
        <div className="my-auto mx-auto">
          {this.props.user.username ? (
            <div>
              <h1 className=" font-weight-light text-center text-dark pt-4 mt-2 title bg-light">
                Hello {this.props.user.username.toUpperCase()}
              </h1>
              <div className=" text-center col-lg-9 col-md-11 mx-auto p-0 mt-5 pb-5">
                {this.buttenChoice()}
              </div>
            </div>
          ) : (
            <div>
              <h1 className="black-title">يرجى تسجيل الدخول</h1>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="my-auto mx-auto">
          <h1 className=" font-weight-light text-center text-dark pt-4 mt-2 title bg-light">
            يرجى الانتظار
          </h1>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    items: state.items.items,
    profile: state.auth.profile
  };
};
const mapDispatchToProps = dispatch => ({
  createOrder: (order, history) =>
    dispatch(actionCreators.createOrder(order, history)),
  waiterCreateOrder: (order, history) =>
    dispatch(actionCreators.waiterCreateOrder(order, history)),
  fetchExpenses: () => dispatch(actionCreators.fetchExpenses())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
